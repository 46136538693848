enum CAT_COLOR {
  BLACK = '#000000',
  BLACK_OLIVE = '#3F3F3F',
  CHINNABAR = '#DF3826',
  DARK_SLATE_GRAY = '#495055',
  DARK_GRAYISH_BLUE = '#43485C',
  DUST = '#666565',
  SILVER = '#C3C7C8',
  GREEN = '#006012',
  FOREST_GREEN = '#008A45',
  SLATE = '#5C656C',
  GREY = '#8D959B',
  BORDER_GRAY = '#DDDDDD',
  TITLE_BORDER_GRAY = '#CCCCCC',
  MEDIUM_GRAY = '#DBDEDF',
  PALE_SKY = '#6E7880',
  LIGHT_GRAY = '#F2F2F2',
  SIDENAV_GRAY = '#F0F0EA',
  MERCURY = '#E6E6E6',
  LIGHT_ORANGE = '#E67300',
  LIGHT_SLATE_GREY = '#78869E',
  STORM_GREY = '#6E7880',
  TRANSFER_TABLE_BORDER_GRAY = '#DDDEDF',
  LYNCH = '#6E7880',
  ORANGE = '#FF8000',
  PIGMENT_RED = '#ED1C24',
  SONIC_SILVER = '#777777',
  STRONG_BLUE = '#0067B8',
  WARNING = '#565656',
  WHITE = '#fff',
  WHITE_SMOKE = '#F6F6F6',
  WHITE_LILAC = '#F5F8FA',
  ALABASTER = '#F9FAFA',
  PORCELAIN = '#EBF1F5',
  SOFT_PEACH = '#FFEAE6',
  LAVENDAR_MIST = '#E6ECF0',
  AZURE = '#1991EB',
  TROPICAL_BLUE = '#C2DDF1',
  WATER_BLUE = '#127ACA',
  MEDIUM_SEA_GREEN = '#33BE63',
  EMERALD = '#61CA7F',
  SEA_MIST = '#B9DFCC',
  SAFETY_ORANGE = '#FD6F02',
  PERSIAN_RED = '#DE2222',
  DARK_RED = '#940000',
  LAVA = '#DA1414',
  GOLDEN_POPPY = '#F3C206',
  BRIGHT_GOLD = '#FFCC11',
  SUPERNOVA = '#FFCC00',
  LIGHTEST_YELLOW = '#FEE082',
  MULLED_WINE = '#4A4B72',
  SUNSET = '#FFD4A8',
  LOADING_BACKDROP = 'rgba(255,255,255,0.4)',
}

export default CAT_COLOR;
