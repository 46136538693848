import { AppState, AppThunkDispatch } from '..';
import { termsAndConditionsVersion } from '../../assets/text/terms-and-conditions';
import UserPreferences from '../../services/user-preferences';
import { loginActionNames } from './types';
import { AxiosResponse } from 'axios';
import { invitationsActionNames } from '../invitations/types';
import { accessRequestActionNames } from '../access-request/types';
import { accessManagementActionNames } from '../access-management/types';
import { CustomerData } from '../../pages/my-settings/constants';
import { showNotification } from '../../utils/util';
import TEXT from '../../globals/translation-map';
import i18n from 'i18next';

export const acceptEULA = (): any => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables, legalAgreementVersion, customSettings },
    } = getState();
    const timestamp = Date.now();
    let termsVersion: any = legalAgreementVersion ? legalAgreementVersion : {};
    termsVersion[termsAndConditionsVersion] = true;
    try {
      const customPreferences = new UserPreferences(
        authInfo,
        environmentVariables.userPreferencesV2,
      );
      await customPreferences.setCustomPreferences({
        lastAgreementFeedbackDateTime: timestamp,
        termsAndConditionsAggrement: JSON.stringify(termsVersion),
      });
    } catch {
      showNotification(dispatch, 'error', i18n.t(TEXT.PREFERENCES.ERRORS.UNABLE_TO_SAVE), {
        persist: true,
      });
    } finally {
      window.localStorage.setItem('legal-agreement-status', 'true');
      dispatch({
        type: loginActionNames.SET_CUSTOM_PREFERENCES,
        payload: {
          legalAgreementTimestamp: timestamp,
          legalAgreementVersion: termsVersion,
          customSettings,
        },
      });
    }
  };
};
export const setDefaultDealer = (dealerCode: string) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      invitation: { invitationsFilters },
      accessManagement: { filters: accessManagementFilters },
      accessRequest: { filters: accessRequestFilters },
    } = getState();
    if (!invitationsFilters.manualSelectedAccountEntry) {
      dispatch({
        type: invitationsActionNames.SET_INVITATION_FILTERS,
        payload: {
          ...invitationsFilters,
          selectedAccount: dealerCode,
        },
      });
    }
    if (!accessRequestFilters.manualSelectedAccountEntry) {
      dispatch({
        type: accessRequestActionNames.SET_ACCESS_REQUEST_FILTERS,
        payload: { ...accessRequestFilters, selectedAccount: dealerCode },
      });
    }
    if (!accessManagementFilters.manualSelectedAccountEntry) {
      dispatch({
        type: accessManagementActionNames.SET_ACCESS_MANAGEMENT_FILTERS,
        payload: {
          ...accessManagementFilters,
          selectedAccount: dealerCode,
        },
      });
    }
  };
};

export const getCustomPreferences = (preferencesRetrievalDisplayed: boolean): any => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    const {
      login: { authInfo, environmentVariables },
    } = getState();
    try {
      const customPreferences = new UserPreferences(
        authInfo,
        environmentVariables.userPreferencesV2,
      );
      const preferencesResponse: AxiosResponse = await customPreferences.getCustomPreferences();
      if (
        preferencesResponse &&
        preferencesResponse.data &&
        preferencesResponse.data.lastAgreementFeedbackDateTime
      ) {
        let termsTimestamp = preferencesResponse.data.lastAgreementFeedbackDateTime;
        let termsVersion = preferencesResponse.data.termsAndConditionsAggrement || {};
        let settings = preferencesResponse.data.customSettings || {
          filterTags: true,
          defaultDealerCode: true,
          customerData: CustomerData.CAT,
        };
        if (typeof termsTimestamp === 'string') termsTimestamp = Number.parseInt(termsTimestamp);
        if (typeof termsVersion === 'string') termsVersion = JSON.parse(termsVersion);
        if (typeof settings === 'string') settings = JSON.parse(settings);
        if (settings?.dealerCode) {
          dispatch(setDefaultDealer(settings.dealerCode));
        }

        dispatch({
          type: loginActionNames.SET_CUSTOM_PREFERENCES,
          payload: {
            legalAgreementTimestamp: termsTimestamp,
            legalAgreementVersion: termsVersion,
            customSettings: settings,
          },
        });
      }
    } catch {
      if (!preferencesRetrievalDisplayed) {
        showNotification(
          dispatch,
          'error',
          i18n.t(TEXT.PREFERENCES.ERRORS.UNABLE_RETRIEVE_PREFERENCES),
          {
            persist: true,
          },
        );
      }
    }
  };
};
