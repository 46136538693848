/* eslint-disable unicorn/numeric-separators-style */
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Link,
  Popper,
  PopperPlacementType,
  Backdrop,
} from '@mui/material';

import { ArrowLeft } from '@mui/icons-material';
import React, { FC, useState, RefObject } from 'react';
import { useSelector } from 'react-redux';
import AdminFormIcon from '../../../../assets/icons/admin-form';
import ChangeAccountIcon from '../../../../assets/icons/change-account';
import ContactSupport from '../../../../assets/icons/contact-support';
import Settings from '../../../../assets/icons/settings';
import SignOut from '../../../../assets/icons/sign-out';
import { PatchUserAddUpdateEntitlementCatSuperAdminInput } from '../../../../entities/user-management-v1/patchUserAddUpdateEntitlementCatSuperAdminInput';
import { PatchUserAddUpdateEntitlementNonCat } from '../../../../entities/user-management-v1/patchUserAddUpdateEntitlementNonCat';
import UserManagement from '../../../../services/user-management';
import { AppState, useDispatchTs } from '../../../../store';
import { showNotification } from '../../../../utils/util';
import Loading from '../../../loading';
import ChangeAccount from '../change-account';
import useStyles from './styles';
import { Link as LinkReact } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';

type UserOptionsProperties = {
  name?: string;
  email: string;
  company: string;
  setRef?: RefObject<HTMLDivElement> | null;
  closeProfileOptions?: () => void;
};

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

const handleAdminForm = () => {
  openInNewTab(
    'https://forms.office.com/pages/responsepage.aspx?id=v3exzjsBq0mKnEq84yr8HlyLJwAtyYNGt_ncdHZsR_FUMUsyOE5VTzZLTTVIRkxGR00yQkdKTEM0MC4u',
  );
};

const handleContactSupport = () => {
  openInNewTab('https://cat-crmi.secure.force.com/CDS?ProductTechnology=CatCustomerAdminTool');
};

const UserOptions: FC<UserOptionsProperties> = (props: UserOptionsProperties) => {
  const { name = '', email, company, setRef = null, closeProfileOptions = () => {} } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatchTs();

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoadingFlag] = useState<boolean>(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();

  const { environmentVariables, partiesResponse, authInfo, permissionsResponse } = useSelector(
    (state: AppState) => state.login,
  );

  const revertSessionStorage = () => {
    window.sessionStorage.setItem('is-refreshed', 'false');
    window.sessionStorage.setItem('sortedBy', '');
    window.sessionStorage.setItem('directionOfSort', '');
    window.sessionStorage.setItem('isSortApplied', '');
    window.localStorage.removeItem('saved-path');
    setTimeout(() => {
      window.location.href = environmentVariables.logoutURI;
    }, 1000);
  };

  const isDealerSupportAdmin =
    partiesResponse?.parties &&
    partiesResponse?.parties.find((item: any) => {
      // We may need to change this logic, waiting on api team
      return item.roles[0].roleName === 'Dealer Support Admin';
    });

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorElement(event.currentTarget);
    setOpen((previous) => placement !== 'left-start' || !previous);
    setPlacement('left-start');
  };

  const revertAccount = async (partiesResponseRevertAccount: any) => {
    if (
      isDealerSupportAdmin &&
      partiesResponseRevertAccount &&
      partiesResponseRevertAccount?.parties
    ) {
      try {
        setLoadingFlag(true);
        const userManagement = new UserManagement(authInfo, environmentVariables.userManagementV1);
        const nonPrimaryParty = partiesResponseRevertAccount?.parties.find((item: any) => {
          return item.partyNumber !== 'APPSUPP';
        });
        const newInput: PatchUserAddUpdateEntitlementCatSuperAdminInput = {
          partyNumber: nonPrimaryParty?.partyNumber,
          roleIds: [],
        };
        const removeEntitlementNonCatInput: PatchUserAddUpdateEntitlementNonCat = {
          entitlements: [newInput],
        };
        if (closeProfileOptions) closeProfileOptions();

        if (
          authInfo?.catrecid &&
          partiesResponseRevertAccount?.parties.length > 1 &&
          nonPrimaryParty?.partyNumber
        ) {
          const updateCatUserResponse = await userManagement.updateCatUser(
            removeEntitlementNonCatInput,
            authInfo?.catrecid,
          );
          if (updateCatUserResponse.status !== 200) {
            showNotification(dispatch, 'error', t(TEXT.USER_MANAGEMENT.ERRORS.USER_UPDATING));
          }
        }
      } catch {
        showNotification(dispatch, 'error', t(TEXT.USER_MANAGEMENT.ERRORS.USER_UPDATING));
      }
      setLoadingFlag(false);
    }
    revertSessionStorage();
  };

  return (
    <Box data-testid="user-options" className={classes.root}>
      <Backdrop
        invisible
        open={!!isLoading}
        style={{ zIndex: 2 }}
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
        }}
      >
        <Loading />
      </Backdrop>
      <Grid className={classes.user} container direction="row" alignItems="center">
        {name && (
          <Grid xs={12} item>
            <Box data-testid="user-options-name" mb={1} className={classes.userName}>
              <Typography variant="h4">{name.toLocaleUpperCase()}</Typography>
            </Box>
          </Grid>
        )}
        <Grid xs={12} item>
          <Box data-testid="user-options-email" mb={1} className={classes.userEmail}>
            <Typography variant="body1">{email}</Typography>
          </Box>
        </Grid>
        <Grid xs={12} item>
          <Box data-testid="user-options-company" className={classes.userCompany}>
            <Typography variant="body2">{company}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Popper style={{ zIndex: 999999 }} open={open} anchorEl={anchorElement} placement={placement}>
        <Box>
          <ChangeAccount setRef={setRef} closeProfileOptions={closeProfileOptions} />
        </Box>
      </Popper>
      <Box className={classes.userOptions} display="flex" alignContent="center">
        <List className={classes.listRoot}>
          {isDealerSupportAdmin && (
            <ListItem className={classes.listItemRoot}>
              <Box
                data-testid="user-options-change-account"
                role="button"
                display="flex"
                width="100%"
                onClick={handleClick}
              >
                <ArrowLeft className={classes.moreOptions} />
                <ListItemIcon>
                  <ChangeAccountIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="h6">{t(TEXT.COMMON.CHANGE_ACCOUNT)}</Typography>}
                />
              </Box>
            </ListItem>
          )}
          {permissionsResponse?.dataPermissions?.customerMasterAuditLogs?.view && (
            <ListItem className={classes.listItemRoot}>
              <Box
                data-testid="add-admin-button"
                role="button"
                display="flex"
                width="100%"
                onClick={handleAdminForm}
                marginLeft={3.5}
              >
                <ListItemIcon>
                  <AdminFormIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="h6">{t(TEXT.COMMON.ADD_ADMIN)}</Typography>}
                />
              </Box>
            </ListItem>
          )}
          <ListItem className={classes.listItemRoot}>
            <Box
              data-testid="contact-support"
              role="button"
              display="flex"
              width="100%"
              onClick={handleContactSupport}
              marginLeft={3.5}
            >
              <ListItemIcon>
                <ContactSupport />
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="h6">{t(TEXT.COMMON.CONTACT_SUPPORT)}</Typography>}
              />
            </Box>
          </ListItem>
          {
            <ListItem className={classes.listItemRoot}>
              <LinkReact
                to="/my-settings"
                className={classes.link}
                data-testid="settings-item"
                onClick={closeProfileOptions}
              >
                <Box role="button" display="flex" width="100%" marginLeft={3.5}>
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant="h6">{t(TEXT.COMMON.SETTINGS)}</Typography>}
                  />
                </Box>
              </LinkReact>
            </ListItem>
          }
          <ListItem className={classes.listItemRoot}>
            <Link
              data-testid="sign-out-item"
              onClick={() => revertAccount(partiesResponse)}
              underline="none"
            >
              <Box role="button" display="flex" width="100%" marginLeft={3.5}>
                <ListItemIcon>
                  <SignOut />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="h6">{t(TEXT.COMMON.SIGN_OUT)}</Typography>}
                />
              </Box>
            </Link>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default UserOptions;
