import { default as Axios, CancelTokenSource } from 'axios';
import { AppState, AppThunkDispatch } from '..';
import { UserAccessRequestsGetResponseObj } from '../../entities/user-onboarding-v1/models';
import AccessRequestsService, {
  AccessRequestSearchCountRequest,
  AccessRequestSearchCountResponse,
  AccessRequestSearchRequest,
} from '../../services/access-requests';
import { accessRequestActionNames } from './types';
import { formatDetailedErrorMessage, showNotification } from '../../utils/util';
import TEXT from '../../globals/translation-map';
import i18n from 'i18next';

let ajaxRequest: CancelTokenSource | null = null;
let ajaxRequestForCount: CancelTokenSource | null = null;

export const fetchAccessRequests = (
  input: AccessRequestSearchRequest & {
    previous?: boolean;
  },
) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    dispatch({ type: accessRequestActionNames.SET_IS_LOADING_ACCESS_REQUEST, payload: true });
    dispatch({
      type: accessRequestActionNames.SET_ACCESS_REQUEST,
      payload: [],
    });
    if (ajaxRequest) {
      ajaxRequest.cancel();
    }
    ajaxRequest = Axios.CancelToken.source();
    const {
      login: { authInfo, environmentVariables },
      accessRequest: { filters, cursorsForAccessRequest },
    } = getState();
    try {
      const accessRequests = new AccessRequestsService(
        authInfo,
        environmentVariables.userOnboardingV1,
      );
      const requestInput = { ...input };
      let currentPage = cursorsForAccessRequest?.currentPage || 0;
      if (input.previous) {
        currentPage -= 1;
        requestInput.cursor = cursorsForAccessRequest?.nextCursors[currentPage - 1];
      } else if (cursorsForAccessRequest?.nextCursors.length > 0) {
        requestInput.cursor = cursorsForAccessRequest?.nextCursors[currentPage];
        currentPage += 1;
      } else {
        currentPage = 0;
      }
      const response = await accessRequests.getAccessRequests(
        requestInput,
        filters?.selectedAccount as string,
      );
      if (response) {
        if (response.status === 200) {
          dispatch({
            type: accessRequestActionNames.SET_ACCESS_REQUEST,
            payload: (response.data as UserAccessRequestsGetResponseObj).requests,
          });

          dispatch({
            type: accessRequestActionNames.SET_CURSOR_FOR_ACCESS_REQUEST,
            payload: {
              cursor: input.previous
                ? undefined
                : (response.data as any)?.responseMetadata?.nextCursor,
              currentPage,
            },
          });

          dispatch({
            type: accessRequestActionNames.SET_IS_LOADING_ACCESS_REQUEST,
            payload: false,
          });
        } else {
          dispatch({
            type: accessRequestActionNames.SET_ACCESS_REQUEST,
            payload: [],
          });
          showNotification(
            dispatch,
            'error',
            i18n.t(TEXT.ACCESS_REQUESTS_SERVICE.ERRORS.GET_ACCESS_REQUEST),
          );
          dispatch({
            type: accessRequestActionNames.SET_IS_LOADING_ACCESS_REQUEST,
            payload: false,
          });
        }
      }
    } catch (error: any) {
      if (!Axios.isCancel(error)) {
        showNotification(
          dispatch,
          'error',
          formatDetailedErrorMessage(error) ||
            i18n.t(TEXT.ACCESS_REQUESTS_SERVICE.ERRORS.GET_ACCESS_REQUEST),
        );
        dispatch({
          type: accessRequestActionNames.SET_IS_LOADING_ACCESS_REQUEST,
          payload: false,
        });
      }
    }
  };
};

export const countAccessRequest = (input: AccessRequestSearchCountRequest) => {
  return async (dispatch: AppThunkDispatch, getState: () => AppState) => {
    if (ajaxRequestForCount) {
      ajaxRequestForCount.cancel();
    }
    ajaxRequestForCount = Axios.CancelToken.source();
    const {
      login: { authInfo, environmentVariables },
    } = getState();
    dispatch({ type: accessRequestActionNames.SET_IS_LOADING_ACCESS_REQUEST_COUNT, payload: true });

    try {
      const AccessRequestService = new AccessRequestsService(
        authInfo,
        environmentVariables.userOnboardingV1,
      );
      const response = await AccessRequestService.countAccessRequest(
        input,
        ajaxRequestForCount?.token,
      );
      if (response) {
        dispatch({
          type: accessRequestActionNames.SET_ACCESS_REQUEST_TOTAL_COUNT,
          payload: (response.data as AccessRequestSearchCountResponse).count,
        });
      }
    } catch (error: any) {
      if (!Axios.isCancel(error)) {
        showNotification(
          dispatch,
          'error',
          formatDetailedErrorMessage(error) ||
            i18n.t(TEXT.ACCESS_REQUESTS_SERVICE.ERRORS.COUNT_ACCESS_REQUEST),
        );
      }
    }
    dispatch({
      type: accessRequestActionNames.SET_IS_LOADING_ACCESS_REQUEST_COUNT,
      payload: false,
    });
  };
};
