import { NavSection, SubNavItem } from '../globals/nav-list';

export const pageKeys = {
  home: 'home',
  admin: 'admin',
  customers: 'customers',
  workQueue: 'workQueue',
  users: 'users',
  assets: 'assets',
  admins: 'admins',
};
export const sortOrder = [
  pageKeys.home,
  pageKeys.admin,
  pageKeys.customers,
  pageKeys.workQueue,
  pageKeys.users,
  pageKeys.assets,
  pageKeys.admins,
];
export const getSortedNavList = (navlist: NavSection) => {
  return Object.entries(navlist)
    .filter(
      ([key, navItem]) => (navItem.subNavItems === undefined || navItem.subNavItems?.length) !== 0,
    )
    .sort((a, b) => {
      return sortOrder.indexOf(a[0]) - sortOrder.indexOf(b[0]);
    });
};

export const findFirstValidNavItem = (navlist: NavSection, existingNav?: string) => {
  let firstUri: string | undefined = undefined;

  existingNav =
    existingNav && (existingNav as string).charAt(0) === '/' ? existingNav : `/${existingNav}`;
  getSortedNavList(navlist)
    // eslint-disable-next-line unicorn/no-array-for-each
    .forEach(([key, navItem]) => {
      if (navItem.uri === existingNav) {
        firstUri = existingNav;
      } else if (!firstUri) {
        firstUri = navItem.uri as string;
      }

      if (navItem.subNavItems && navItem.subNavItems?.length) {
        for (let subnavItem of navItem?.subNavItems as SubNavItem[]) {
          if (subnavItem.uri === existingNav) {
            firstUri = existingNav;
          }
        }
      }
    });
  return firstUri;
};
