import React, {
  useRef,
  ReactNode,
  ReactElement,
  MouseEvent,
  useState,
  useEffect,
  Ref,
} from 'react';
import { Box, Table, TableBody, TableFooter, TableHead, Typography } from '@mui/material';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import HeaderRow from './components/header-row';
import Footer from './components/footer-table';
import useStyles from './styles';
import memoize from 'memoize-one';
import Row from './components/row';
import { useTranslation } from 'react-i18next';
import TEXT from '../../globals/translation-map';

export type InfiniteScrollTableType = {
  rows: Array<any>;
  columns: any;
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  loadNextPage: (startIndex: number, stopIndex: number) => Promise<any> | null;
  height: number;
  expandedRowFlag?: boolean | undefined;
  expandOneRowOnlyFlag?: boolean;
  screenWidth?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  expandedRowComponent: (item: any) => ReactNode;
  onClickOfRow?: (item: any, event: MouseEvent) => void;
  remainingItemsCount?: number;
  nonExpandedRowSizeBasedOnScreenRes: {
    xl: number;
    lg: number;
    md: number;
    sm: number;
    xs: number;
  };
  expandedRowSizeBasedOnScreenRes?: {
    xl: number;
    lg: number;
    md: number;
    sm: number;
    xs: number;
  };
  noDataComponent?: ReactElement | null;
  onExpansion?: Function;
  skipEntitlementCheck?: boolean;
  noDataMarginTop?: number;
};
const createItemData = memoize(
  (
    items,
    toggleItemActive,
    expandedRowComponent,
    rows,
    expandedRows,
    autoSetRowHeight,
    skipEntitlementCheck,
    onClickOfRow,
    expandedRowFlag,
    columns,
    listReference,
    rowSizeList,
    nonExpandedRowHeight,
    onExpansion,
    autoAdjustRowDetailHeight,
  ) => ({
    items,
    toggleItemActive,
    expandedRowComponent,
    rows,
    expandedRows,
    autoSetRowHeight,
    skipEntitlementCheck,
    onClickOfRow,
    expandedRowFlag,
    columns,
    listReference,
    rowSizeList,
    nonExpandedRowHeight,
    onExpansion,
    autoAdjustRowDetailHeight,
  }),
);

const VariableRowInfiniteScrollTable = (props: InfiniteScrollTableType) => {
  const { t } = useTranslation();
  const {
    columns,
    rows,
    isNextPageLoading,
    loadNextPage,
    hasNextPage,
    height,
    expandedRowFlag,
    expandOneRowOnlyFlag = false,
    expandedRowComponent,
    onClickOfRow,
    screenWidth,
    remainingItemsCount,
    nonExpandedRowSizeBasedOnScreenRes,
    expandedRowSizeBasedOnScreenRes,
    noDataComponent,
    onExpansion,
    skipEntitlementCheck,
    noDataMarginTop,
  } = props;

  const { classes } = useStyles();
  const itemData = {
    rows,
    columns,
  };

  //TODO: fix warning, Invalid list ref
  const listReference = useRef<any>(null);
  const itemCount = hasNextPage ? rows.length + 1 : rows.length;
  // eslint-disable-next-line unicorn/no-new-array
  const defaultRowSizes = new Array(1000)
    .fill(true)
    .map(() => nonExpandedRowSizeBasedOnScreenRes[screenWidth || 'xs']);
  const [rowSizeList, setRowSizeList] = useState<number[]>(defaultRowSizes);

  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const [nonExpandedRowHeight, setNonExpandedRowHeight] = useState<number>(
    nonExpandedRowSizeBasedOnScreenRes[screenWidth || 'xs'],
  );
  const [expandedRowHeight, setExpandedRowHeight] = useState<number>(
    (expandedRowSizeBasedOnScreenRes && expandedRowSizeBasedOnScreenRes[screenWidth || 'xs']) ||
      nonExpandedRowSizeBasedOnScreenRes[screenWidth || 'xs'],
  );

  const autoAdjustRowDetailHeight = expandedRowSizeBasedOnScreenRes ? false : true;

  const [containerWidth, setContainerWidth] = useState<number | undefined>();
  const ref: Ref<any> = useRef(null);

  useEffect(() => {
    setExpandedRows([]);
    // eslint-disable-next-line unicorn/no-new-array
    const newRowSizes = new Array(1000)
      .fill(true)
      .map(() => nonExpandedRowSizeBasedOnScreenRes[screenWidth || 'xs']);

    setRowSizeList(newRowSizes);
    if (listReference && listReference?.current) {
      listReference?.current.resetAfterIndex(0);
    }
  }, [rows]);
  useEffect(() => {
    // TODO: hook gets called anytime parent is re-rendered
    // pass const as dependency from parent or optimize this
    // eslint-disable-next-line unicorn/no-new-array
    const newRowSizes = new Array(itemCount || 1000)
      .fill(true)
      .map(() => nonExpandedRowSizeBasedOnScreenRes[screenWidth || 'xs']);

    setRowSizeList(newRowSizes);
    setNonExpandedRowHeight(nonExpandedRowSizeBasedOnScreenRes[screenWidth || 'xs']);
    setExpandedRowHeight(
      expandedRowSizeBasedOnScreenRes
        ? expandedRowSizeBasedOnScreenRes[screenWidth || 'xs']
        : nonExpandedRowSizeBasedOnScreenRes[screenWidth || 'xs'],
    );
    // Quick change in screenWidth results in size not being set on <list> properly
    if (listReference && listReference?.current) {
      listReference?.current.resetAfterIndex(0);
    }
  }, [expandedRowSizeBasedOnScreenRes, nonExpandedRowSizeBasedOnScreenRes, screenWidth]);

  const loadMoreItems = isNextPageLoading
    ? () => {
        return null;
      }
    : loadNextPage;

  const isItemLoaded = (index: number) => !hasNextPage || index < rows.length;

  const expandRows = (index: number) => {
    if (expandOneRowOnlyFlag) {
      let newRowSizeList = [...defaultRowSizes];

      if (expandedRows.includes(index)) {
        newRowSizeList[index] = nonExpandedRowHeight;
        setExpandedRows([]);
        setRowSizeList([...newRowSizeList]);
      } else {
        setExpandedRows([index]);
        if (expandedRowSizeBasedOnScreenRes) {
          newRowSizeList[index] = expandedRowHeight;
        } else {
          newRowSizeList[index] = 450;
        }
        setRowSizeList([...newRowSizeList]);
        if (listReference && listReference?.current) {
          (window as any)['listReference'] = listReference;
          //keep the expanded item in window so it does not get hidden behind viewfold
          setTimeout(() => {
            listReference?.current.resetAfterIndex(0, true);
            listReference.current.scrollToItem(index, 'top');
          }, 10);
        }
      }
      onExpansion && onExpansion(rows[index]);
    } else {
      if ((listReference && listReference?.current) || null) {
        listReference?.current.resetAfterIndex(index);
      }
      if (expandedRows.includes(index)) {
        rowSizeList[index] = nonExpandedRowHeight;

        setExpandedRows([...expandedRows].filter((item) => item !== index));
      } else {
        const row = [...expandedRows];

        row.push(index);
        setExpandedRows(row);

        if (expandedRowSizeBasedOnScreenRes) {
          rowSizeList[index] = expandedRowHeight;
        } else {
          rowSizeList[index] = nonExpandedRowHeight;
        }
      }
      onExpansion && onExpansion(rows[index]);
      setRowSizeList([...rowSizeList]);
    }
  };

  const autoSetRowHeight = (heightAutoSet: number, index: number) => {
    setTimeout(() => {
      let newRowSizeList = expandOneRowOnlyFlag ? [...defaultRowSizes] : [...rowSizeList];
      newRowSizeList[index] = heightAutoSet + nonExpandedRowHeight;
      setRowSizeList([...newRowSizeList]);
      if ((listReference && listReference?.current) || null) {
        listReference?.current.resetAfterIndex(0, true);
      }
    }, 50);
  };

  useEffect(() => {
    if (!ref.current || !isItemLoaded) {
      return; // wait for the elementRef to be available
    }
    // calculate scroll width
    if (ref?.current?.children?.length && ref?.current?.children?.length) {
      const scrollWidth =
        ref?.current?.offsetWidth - ref.current?.children[0]?.children[0]?.offsetWidth;
      if (scrollWidth > 100) {
        setContainerWidth(undefined);
      } else {
        setContainerWidth(
          ref?.current?.offsetWidth - ref.current?.children[0]?.children[0]?.offsetWidth,
        );
      }
    }
  }, [ref?.current?.offsetWidth, isItemLoaded]);

  const toggleSize = (index: number) => {
    expandRows(index);
    if (listReference.current) {
      listReference.current.resetAfterIndex(0);
    }
  };
  const itemDataMemoized = createItemData(
    itemData,
    toggleSize,
    expandedRowComponent,
    rows,
    expandedRows,
    autoSetRowHeight,
    skipEntitlementCheck,
    onClickOfRow,
    expandedRowFlag,
    columns,
    listReference,
    rowSizeList,
    nonExpandedRowHeight,
    onExpansion,
    autoAdjustRowDetailHeight,
  );

  return (
    <Table className={classes.root} component="div" data-testid="infinite-loader">
      <TableHead component="div">
        <HeaderRow
          columns={columns}
          expandedRowFlag={expandedRowFlag}
          scrollWidth={containerWidth}
        />
      </TableHead>
      <TableBody className={classes.tbody} component="div" ref={ref}>
        {!hasNextPage && itemCount === 0 && (
          <Box
            width="100%"
            minHeight={100}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderBottom={noDataComponent ? 'unset' : '1px solid rgba(224, 224, 224, 1)'}
            mt={noDataComponent ? (noDataMarginTop ? noDataMarginTop : 20) : 'unset'}
          >
            {noDataComponent ? (
              noDataComponent
            ) : (
              <Typography style={{ fontWeight: 600 }}>
                {t(TEXT.COMMON.METADATA.BASE.NO_WORK_QUEUE)}
              </Typography>
            )}
          </Box>
        )}
        {(hasNextPage || itemCount !== 0) && (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems as any}
            data-attr-id="infinite-table"
          >
            {({ onItemsRendered, ref: refCb }) => (
              <List
                height={height}
                width="100%"
                itemCount={itemCount}
                itemSize={(index) => {
                  return rowSizeList[index];
                }}
                onItemsRendered={onItemsRendered}
                ref={(list) => {
                  refCb(list);
                  listReference.current = list;
                }}
                overscanCount={1}
                style={{ overflowY: 'auto' }}
                itemData={itemDataMemoized}
              >
                {Row}
              </List>
            )}
          </InfiniteLoader>
        )}
      </TableBody>
      {remainingItemsCount && remainingItemsCount > 0 ? (
        <TableFooter>
          <Footer footerCount={remainingItemsCount} />
        </TableFooter>
      ) : null}
    </Table>
  );
};

export default VariableRowInfiniteScrollTable;
