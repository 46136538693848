import {
  CustomerOrganizationActionTypes,
  RecommendationFilterType,
  recommendationsActionNames,
  RecommendationState,
} from './types';

const initialState: RecommendationState = {
  searchInputForRecommendations: '',
  itemsForSelectedRecommendationGroupId: [],
  myRecommendations: [],
  duplicateCustomers: [],
  cursorsForRecommendations: {
    nextCursors: [],
    currentPage: undefined,
  },
  selectedRecommendation: undefined,
  sortBy: 'initiatedTime',
  orderBy: 'DESC',
  staleData: false,
};

const recommendationReducer = (
  state: RecommendationState = initialState,
  action: CustomerOrganizationActionTypes,
): RecommendationState => {
  switch (action.type) {
    case recommendationsActionNames.SET_MY_RECOMMENDATIONS: {
      return {
        ...state,
        myRecommendations: action.payload,
      };
    }
    case recommendationsActionNames.SET_DUPLICATE_CUSTOMERS_RECOMMENDATIONS: {
      return {
        ...state,
        duplicateCustomers: action.payload,
      };
    }
    case recommendationsActionNames.SET_ASSOCIATION_FEEDBACK_REQUEST: {
      return {
        ...state,
        associationFeedback: { ...state.associationFeedback, request: action.payload },
      };
    }
    case recommendationsActionNames.SET_ASSOCIATION_FEEDBACK_RESPONSE: {
      return {
        ...state,
        associationFeedback: { ...state.associationFeedback, response: action.payload },
      };
    }
    case recommendationsActionNames.SET_IS_LOADING_DUPLICATE_CUSTOMERS_RECOMMENDATIONS: {
      return {
        ...state,
        isLoadingDuplicateCustomers: action.payload,
      };
    }
    case recommendationsActionNames.SET_SELECTED_RECOMMENDATION: {
      return {
        ...state,
        selectedRecommendation: action.payload,
      };
    }
    case recommendationsActionNames.SET_UPDATED_CUSTOMER_NAME: {
      return {
        ...state,
        selectedRecommendation: {
          ...state.selectedRecommendation,
          updatedCustomerName: action.payload,
        },
      };
    }
    case recommendationsActionNames.SET_COUNT_FOR_ITEMS: {
      return {
        ...state,
        totalItemsCount: action.payload,
      };
    }
    case recommendationsActionNames.SET_ITEMS_FOR_SELECTED_RECOMMENDATION_GROUP_ID: {
      return {
        ...state,
        itemsForSelectedRecommendationGroupId: [
          ...(state.itemsForSelectedRecommendationGroupId || []),
          ...action.payload,
        ],
      };
    }
    case recommendationsActionNames.SET_PRIMARY_CUSTOMER_DETAILS_FOR_RECOMMENDATIONS: {
      return {
        ...state,
        primaryCustomerOrganizationDetails: action.payload,
      };
    }
    case recommendationsActionNames.ADD_FEEDBACK_FOR_ITEM: {
      return {
        ...state,
        itemsFeedbackForSelectedRecommendationGroupId: action.payload,
      };
    }
    case recommendationsActionNames.CLEAR_MY_RECOMMENDATIONS: {
      return {
        ...state,
        myRecommendations: initialState.myRecommendations,
      };
    }
    case recommendationsActionNames.CLEAR_RECOMMENDATION_ITEMS: {
      return {
        ...state,
        itemsForSelectedRecommendationGroupId: initialState.itemsForSelectedRecommendationGroupId,
        cursorForItems: initialState.cursorForItems,
      };
    }
    case recommendationsActionNames.CLEAR_CUSTOMER_ORGANIZATION_ITEMS: {
      return {
        ...state,
        primaryCustomerOrganizationDetails: initialState.primaryCustomerOrganizationDetails,
      };
    }
    case recommendationsActionNames.SET_CURSORS_FOR_ITEMS: {
      return {
        ...state,
        cursorForItems: action.payload,
      };
    }
    case recommendationsActionNames.SET_CURSORS_FOR_RECOMMENDATIONS: {
      const nextCursors = [...(state.cursorsForRecommendations?.nextCursors || [])];
      if (action.payload.currentPage >= state.cursorsForRecommendations?.nextCursors.length) {
        nextCursors.push(action.payload.cursor);
      }
      return {
        ...state,
        cursorsForRecommendations: {
          nextCursors,
          currentPage: action.payload.currentPage,
        },
      };
    }
    case recommendationsActionNames.RESET_CURSORS_FOR_RECOMMENDATIONS: {
      return {
        ...state,
        cursorsForRecommendations: { currentPage: 0, nextCursors: [] },
      };
    }
    case recommendationsActionNames.SET_IS_LOADING_RECOMMENDATIONS: {
      return {
        ...state,
        isLoadingRecommendations: action.payload,
      };
    }
    case recommendationsActionNames.SET_TOTAL_COUNT: {
      return {
        ...state,
        totalRecommendationCount: action.payload,
      };
    }
    case recommendationsActionNames.SET_IS_LOADING_COUNT: {
      return {
        ...state,
        isLoadingRecommendationsCount: action.payload,
      };
    }
    case recommendationsActionNames.SET_SEARCH_INPUT_FOR_RECOMMENDATIONS: {
      return {
        ...state,
        searchInputForRecommendations: action.payload,
      };
    }
    case recommendationsActionNames.SET_RECOMMENDATION_FILTERS: {
      return {
        ...state,
        recommendationFilters: action.payload,
      };
    }
    case recommendationsActionNames.DELETE_RECOMMENDATION_FILTER_VALUE_BY_TYPE: {
      let recommendationFilters = { ...state.recommendationFilters };
      if (state.recommendationFilters && state.recommendationFilters[action.payload.propertyName]) {
        switch (action.payload.propertyName) {
          case RecommendationFilterType.dealerCode:
            recommendationFilters.dealerCode = state.recommendationFilters?.dealerCode?.filter(
              (value: string) => value !== action.payload.propertyValue,
            );

            if (recommendationFilters.dealerCode?.length === 0) {
              delete recommendationFilters[action.payload.propertyName];
            }

            break;
          case RecommendationFilterType.startDate:
            delete recommendationFilters[action.payload.propertyName];
            delete recommendationFilters[RecommendationFilterType.endDate];

            break;
          default:
            delete recommendationFilters[action.payload.propertyName];
        }
      }

      return {
        ...state,
        recommendationFilters,
      };
    }
    case recommendationsActionNames.RESET_RECOMMENDATIONS: {
      return {
        ...initialState,
      };
    }
    case recommendationsActionNames.SET_CUSTOMER_RECOMMENDATIONS_SORT: {
      return {
        ...state,
        sortBy: action.payload,
      };
    }
    case recommendationsActionNames.SET_CUSTOMER_RECOMMENDATIONS_ORDER: {
      return {
        ...state,
        orderBy: action.payload,
      };
    }
    case recommendationsActionNames.SET_CUSTOMER_RECOMMENDATIONS_STALE_DATA: {
      return {
        ...state,
        staleData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default recommendationReducer;
