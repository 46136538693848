/* eslint-disable unicorn/no-array-for-each */
// Online convert link: https://www.convertcsv.com/csv-to-json.htm
// For inputCountries select Step 4: Custom output via Template and change the repeating section rule to the following:
// {f2}: {lb}
// title:'{f1}'
// {rb}

import { CountrySearchResponseItem } from '../entities/countries-v1/countrySearchResponseItem';

// For inputStates select Step 5: Generate Output with CSV to Keyed Json (need to replace all for 'state' key)

export type Country = {
  [key: string]: {
    title: string;
    states?: Array<{ title: string; code: string; abbreviations?: Array<string> }>;
    code?: string;
  };
};

export type State = {
  [key: string]: Array<{
    state: string;
  }>;
};

type StateWithabbreviations = {
  [key: string]: {
    abbreviations: Array<string>;
  };
};

export const countriesWithNoZipCode: Country = {
  PRY: {
    title: 'PARAGUAY',
  },
  COL: {
    title: 'COLOMBIA',
  },
  DOM: {
    title: 'DOMINICAN REPUBLIC',
  },
  BHS: {
    title: 'BAHAMAS',
  },
  JAM: {
    title: 'JAMAICA',
  },
  NIC: {
    title: 'NICARAGUA',
  },
  ECU: {
    title: 'ECUADOR',
  },
  CHL: {
    title: 'CHILE',
  },
  BOL: {
    title: 'BOLIVIA (PLURINATIONAL STATE OF)',
  },
  URY: {
    title: 'URUGUAY',
  },
  ARE: {
    title: 'UNITED ARAB EMIRATES',
  },
};

const inputStates: State = {
  USA: [
    {
      state: 'Alabama',
    },
    {
      state: 'Alaska',
    },
    {
      state: 'Arizona',
    },
    {
      state: 'Arkansas',
    },
    {
      state: 'California',
    },
    {
      state: 'Colorado',
    },
    {
      state: 'Connecticut',
    },
    {
      state: 'Delaware',
    },
    {
      state: 'District of Columbia',
    },
    {
      state: 'Florida',
    },
    {
      state: 'Georgia',
    },
    {
      state: 'Hawaii',
    },
    {
      state: 'Idaho',
    },
    {
      state: 'Illinois',
    },
    {
      state: 'Indiana',
    },
    {
      state: 'Iowa',
    },
    {
      state: 'Kansas',
    },
    {
      state: 'Kentucky',
    },
    {
      state: 'Louisiana',
    },
    {
      state: 'Maine',
    },
    {
      state: 'Maryland',
    },
    {
      state: 'Massachusetts',
    },
    {
      state: 'Michigan',
    },
    {
      state: 'Minnesota',
    },
    {
      state: 'Mississippi',
    },
    {
      state: 'Missouri',
    },
    {
      state: 'Montana',
    },
    {
      state: 'Nebraska',
    },
    {
      state: 'Nevada',
    },
    {
      state: 'New Hampshire',
    },
    {
      state: 'New Jersey',
    },
    {
      state: 'New Mexico',
    },
    {
      state: 'New York',
    },
    {
      state: 'North Carolina',
    },
    {
      state: 'North Dakota',
    },
    {
      state: 'Ohio',
    },
    {
      state: 'Oklahoma',
    },
    {
      state: 'Oregon',
    },
    {
      state: 'Pennsylvania',
    },
    {
      state: 'Rhode Island',
    },
    {
      state: 'South Carolina',
    },
    {
      state: 'South Dakota',
    },
    {
      state: 'Tennessee',
    },
    {
      state: 'Texas',
    },
    {
      state: 'Utah',
    },
    {
      state: 'Vermont',
    },
    {
      state: 'Virginia',
    },
    {
      state: 'Washington',
    },
    {
      state: 'West Virginia',
    },
    {
      state: 'Wisconsin',
    },
    {
      state: 'Wyoming',
    },
  ],
};

// Online converter: https://www.convertcsv.com/csv-to-json.htm
// For inputStateabbreviations select step 4
// Top: {
// Repeating Section for each Line of CSV:  '{f1}':{
//                                                  abbreviations: ['{f2}', '{f3}']}
//                                            }
// Bottom: }
const inputStateabbreviations: StateWithabbreviations = {
  Alaska: {
    abbreviations: ['AK', 'Alaska'],
  },
  Arizona: {
    abbreviations: ['AZ', 'Ariz.'],
  },
  Arkansas: {
    abbreviations: ['AR', 'Ark.'],
  },
  California: {
    abbreviations: ['CA', 'Calif.'],
  },
  Colorado: {
    abbreviations: ['CO', 'Colo.'],
  },
  Connecticut: {
    abbreviations: ['CT', 'Conn.'],
  },
  Delaware: {
    abbreviations: ['DE', 'Del.'],
  },
  'District of Columbia': {
    abbreviations: ['DC', 'D.C.'],
  },
  Florida: {
    abbreviations: ['FL', 'Fla.'],
  },
  Georgia: {
    abbreviations: ['GA', 'Ga.'],
  },
  Hawaii: {
    abbreviations: ['HI', 'Hawaii'],
  },
  Idaho: {
    abbreviations: ['ID', 'Idaho'],
  },
  Illinois: {
    abbreviations: ['IL', 'Ill.'],
  },
  Indiana: {
    abbreviations: ['IN', 'Ind.'],
  },
  Iowa: {
    abbreviations: ['IA', 'Iowa'],
  },
  Kansas: {
    abbreviations: ['KS', 'Kans.'],
  },
  Kentucky: {
    abbreviations: ['KY', 'Ky.'],
  },
  Louisiana: {
    abbreviations: ['LA', 'La.'],
  },
  Maine: {
    abbreviations: ['ME', 'Maine'],
  },
  Maryland: {
    abbreviations: ['MD', 'Md.'],
  },
  Massachusetts: {
    abbreviations: ['MA', 'Mass.'],
  },
  Michigan: {
    abbreviations: ['MI', 'Mich.'],
  },
  Minnesota: {
    abbreviations: ['MN', 'Minn.'],
  },
  Mississippi: {
    abbreviations: ['MS', 'Miss.'],
  },
  Missouri: {
    abbreviations: ['MO', 'Mo.'],
  },
  Montana: {
    abbreviations: ['MT', 'Mont.'],
  },
  Nebraska: {
    abbreviations: ['NE', 'Nebr.'],
  },
  Nevada: {
    abbreviations: ['NV', 'Nev.'],
  },
  'New Hampshire': {
    abbreviations: ['NH', 'N.H.'],
  },
  'New Jersey': {
    abbreviations: ['NJ', 'N.J.'],
  },
  'New Mexico': {
    abbreviations: ['NM', 'N. Mex.'],
  },
  'New York': {
    abbreviations: ['NY', 'N.Y.'],
  },
  'North Carolina': {
    abbreviations: ['NC', 'N.C.'],
  },
  'North Dakota': {
    abbreviations: ['ND', 'N. Dak.'],
  },
  Ohio: {
    abbreviations: ['OH', 'Ohio'],
  },
  Oklahoma: {
    abbreviations: ['OK', 'Okla.'],
  },
  Oregon: {
    abbreviations: ['OR', 'Oreg.'],
  },
  Pennsylvania: {
    abbreviations: ['PA', 'Pa.'],
  },
  'Rhode Island': {
    abbreviations: ['RI', 'R.I.'],
  },
  'South Carolina': {
    abbreviations: ['SC', 'S.C.'],
  },
  'South Dakota': {
    abbreviations: ['SD', 'S. Dak.'],
  },
  Tennessee: {
    abbreviations: ['TN', 'Tenn.'],
  },
  Texas: {
    abbreviations: ['TX', 'Tex.'],
  },
  Utah: {
    abbreviations: ['UT', 'Utah'],
  },
  Vermont: {
    abbreviations: ['VT', 'Vt.'],
  },
  Virginia: {
    abbreviations: ['VA', 'Va.'],
  },
  Washington: {
    abbreviations: ['WA', 'Wash.'],
  },
  'West Virginia': {
    abbreviations: ['WV', 'W. Va.'],
  },
  Wisconsin: {
    abbreviations: ['WI', 'Wis.'],
  },
  Wyoming: {
    abbreviations: ['WY', 'Wyo.'],
  },
  'American Samoa': {
    abbreviations: ['AS', 'A.S.'],
  },
  Guam: {
    abbreviations: ['GU', 'Guam'],
  },
  'Puerto Rico': {
    abbreviations: ['PR', 'P.R.'],
  },
  'U.S. Virgin Islands': {
    abbreviations: ['VI', 'V.I.'],
  },
};
const inputCountries: Country = {
  LCA: {
    title: 'Saint Lucia',
  },
  LIE: {
    title: 'Liechtenstein',
  },
  LKA: {
    title: 'Sri Lanka',
  },
  LBR: {
    title: 'Liberia',
  },
  LSO: {
    title: 'Lesotho',
  },
  LTU: {
    title: 'Lithuania',
  },
  LUX: {
    title: 'Luxembourg',
  },
  LVA: {
    title: 'Latvia',
  },
  LBY: {
    title: 'Libya',
  },
  MAR: {
    title: 'Morocco',
  },
  MCO: {
    title: 'Monaco',
  },
  MDA: {
    title: 'Moldova (the Republic of)',
  },
  MNE: {
    title: 'Montenegro',
  },
  MAF: {
    title: 'Saint Martin (Frenchpart)',
  },
  MDG: {
    title: 'Madagascar',
  },
  SGS: {
    title: 'South Georgia and South Sandwich Islands',
  },
  GTM: {
    title: 'Guatemala',
  },
  GUM: {
    title: 'Guam',
  },
  GNB: {
    title: 'Guinea-Bissau',
  },
  GUY: {
    title: 'Guyana',
  },
  HKG: {
    title: 'Hong Kong',
  },
  HMD: {
    title: 'Heard Island and McDonald Islands',
  },
  HND: {
    title: 'Honduras',
  },
  HRV: {
    title: 'Croatia',
  },
  HTI: {
    title: 'Haiti',
  },
  HUN: {
    title: 'Hungary',
  },
  IDN: {
    title: 'Indonesia',
  },
  IRL: {
    title: 'Ireland',
  },
  ISR: {
    title: 'Israel',
  },
  IMN: {
    title: 'Isle of Man',
  },
  IND: {
    title: 'India',
  },
  IOT: {
    title: 'British Indian Ocean Territory',
  },
  IRQ: {
    title: 'Iraq',
  },
  ISL: {
    title: 'Iceland',
  },
  ITA: {
    title: 'Italy',
  },
  JEY: {
    title: 'Jersey',
  },
  JAM: {
    title: 'Jamaica',
  },
  JOR: {
    title: 'Jordan',
  },
  JPN: {
    title: 'Japan',
  },
  KEN: {
    title: 'Kenya',
  },
  KGZ: {
    title: 'Kyrgyzstan',
  },
  KHM: {
    title: 'Cambodia',
  },
  KIR: {
    title: 'Kiribati',
  },
  COM: {
    title: 'Comoros (the)',
  },
  KNA: {
    title: 'Saint Kitts and Nevis',
  },
  KOR: {
    title: 'Korea (the Republic of)',
  },
  KWT: {
    title: 'Kuwait',
  },
  CYM: {
    title: 'Cayman Islands (the)',
  },
  KAZ: {
    title: 'Kazakhstan',
  },
  LAO: {
    title: `Lao People's Democratic Republic (the)`,
  },
  MHL: {
    title: 'Marshall Islands (the)',
  },
  MKD: {
    title: 'North Macedonia',
  },
  MLI: {
    title: 'Mali',
  },
  MMR: {
    title: 'Myanmar',
  },
  MNG: {
    title: 'Mongolia',
  },
  MAC: {
    title: 'Macao',
  },
  MNP: {
    title: 'Northern Mariana Islands (the)',
  },
  MTQ: {
    title: 'Martinique',
  },
  MRT: {
    title: 'Mauritania',
  },
  MSR: {
    title: 'Montserrat',
  },
  MLT: {
    title: 'Malta',
  },
  MUS: {
    title: 'Mauritius',
  },
  MDV: {
    title: 'Maldives',
  },
  MWI: {
    title: 'Malawi',
  },
  MEX: {
    title: 'Mexico',
  },
  MYS: {
    title: 'Malaysia',
  },
  MOZ: {
    title: 'Mozambique',
  },
  NAM: {
    title: 'Namibia',
  },
  NCL: {
    title: 'New Caledonia',
  },
  NER: {
    title: 'Niger (the)',
  },
  NFK: {
    title: 'Norfolk Island',
  },
  NGA: {
    title: 'Nigeria',
  },
  NIC: {
    title: 'Nicaragua',
  },
  NLD: {
    title: 'Netherlands (the)',
  },
  NOR: {
    title: 'Norway',
  },
  NPL: {
    title: 'Nepal',
  },
  NRU: {
    title: 'Nauru',
  },
  NIU: {
    title: 'Niue',
  },
  NZL: {
    title: 'New Zealand',
  },
  OMN: {
    title: 'Oman',
  },
  PAN: {
    title: 'Panama',
  },
  PER: {
    title: 'Peru',
  },
  PYF: {
    title: 'French Polynesia',
  },
  PNG: {
    title: 'Papua New Guinea',
  },
  PHL: {
    title: 'Philippines (the)',
  },
  AND: {
    title: 'Andorra',
  },
  ARE: {
    title: 'United Arab Emirates',
  },
  AFG: {
    title: 'Afghanistan',
  },
  ATG: {
    title: 'Antigua and Barbuda',
  },
  AIA: {
    title: 'Anguilla',
  },
  ALB: {
    title: 'Albania',
  },
  ARM: {
    title: 'Armenia',
  },
  AGO: {
    title: 'Angola',
  },
  ATA: {
    title: 'Antarctica',
  },
  ARG: {
    title: 'Argentina',
  },
  ASM: {
    title: 'American Samoa',
  },
  AUT: {
    title: 'Austria',
  },
  AUS: {
    title: 'Australia',
  },
  ABW: {
    title: 'Aruba',
  },
  BRB: {
    title: 'Barbados',
  },
  ZWE: {
    title: 'Zimbabwe',
  },
  FIN: {
    title: 'Finland',
  },
  FJI: {
    title: 'Fiji',
  },
  FLK: {
    title: 'Falkland Islands (the Malvinas)',
  },
  ALA: {
    title: 'Åland Islands',
  },
  AZE: {
    title: 'Azerbaijan',
  },
  BIH: {
    title: 'Bosnia and Herzegovina',
  },
  BGD: {
    title: 'Bangladesh',
  },
  BEL: {
    title: 'Belgium',
  },
  BFA: {
    title: 'Burkina Faso',
  },
  BGR: {
    title: 'Bulgaria',
  },
  BHR: {
    title: 'Bahrain',
  },
  BDI: {
    title: 'Burundi',
  },
  BEN: {
    title: 'Benin',
  },
  BLM: {
    title: 'Saint Barthélemy',
  },
  BMU: {
    title: 'Bermuda',
  },
  BRN: {
    title: 'Brunei Darussalam',
  },
  BOL: {
    title: 'Bolivia (Plurinational State of)',
  },
  BES: {
    title: 'Bonaire, Sint Eustatius and Saba',
  },
  BRA: {
    title: 'Brazil',
  },
  BHS: {
    title: 'Bahamas (the)',
  },
  BTN: {
    title: 'Bhutan',
  },
  BVT: {
    title: 'Bouvet Island',
  },
  BWA: {
    title: 'Botswana',
  },
  BLR: {
    title: 'Belarus',
  },
  BLZ: {
    title: 'Belize',
  },
  CAN: {
    title: 'Canada',
  },
  CCK: {
    title: 'Cocos (Keeling) Islands',
  },
  COD: {
    title: 'Congo (Democratic Republic)',
  },
  CAF: {
    title: 'Central African Republic',
  },
  COG: {
    title: 'Congo (the)',
  },
  CHE: {
    title: 'Switzerland',
  },
  CIV: {
    title: `Côte d'Ivoire`,
  },
  COK: {
    title: 'Cook Islands (the)',
  },
  CHL: {
    title: 'Chile',
  },
  CMR: {
    title: 'Cameroon',
  },
  CHN: {
    title: 'China',
  },
  COL: {
    title: 'Colombia',
  },
  CRI: {
    title: 'Costa Rica',
  },
  CPV: {
    title: 'Cabo Verde',
  },
  CUW: {
    title: 'Curaçao',
  },
  CXR: {
    title: 'Christmas Island',
  },
  CYP: {
    title: 'Cyprus',
  },
  CZE: {
    title: 'Czechia',
  },
  DEU: {
    title: 'Germany',
  },
  DJI: {
    title: 'Djibouti',
  },
  DNK: {
    title: 'Denmark',
  },
  DMA: {
    title: 'Dominica',
  },
  DOM: {
    title: 'Dominican Republic (the)',
  },
  DZA: {
    title: 'Algeria',
  },
  ECU: {
    title: 'Ecuador',
  },
  EST: {
    title: 'Estonia',
  },
  EGY: {
    title: 'Egypt',
  },
  ESH: {
    title: 'Western Sahara',
  },
  ERI: {
    title: 'Eritrea',
  },
  ESP: {
    title: 'Spain',
  },
  ETH: {
    title: 'Ethiopia',
  },
  FSM: {
    title: 'Micronesia (Federated States of)',
  },
  FRO: {
    title: 'Faroe Islands (the)',
  },
  FRA: {
    title: 'France',
  },
  GAB: {
    title: 'Gabon',
  },
  GBR: {
    title: 'United Kingdom of Great Britain and Northern Ireland (the)',
  },
  GRD: {
    title: 'Grenada',
  },
  GEO: {
    title: 'Georgia',
  },
  GUF: {
    title: 'French Guiana',
  },
  GGY: {
    title: 'Guernsey',
  },
  GHA: {
    title: 'Ghana',
  },
  GIB: {
    title: 'Gibraltar',
  },
  GRL: {
    title: 'Greenland',
  },
  GMB: {
    title: 'Gambia (the)',
  },
  GIN: {
    title: 'Guinea',
  },
  GLP: {
    title: 'Guadeloupe',
  },
  GNQ: {
    title: 'Equatorial Guinea',
  },
  GRC: {
    title: 'Greece',
  },
  PSE: {
    title: 'Palestine (the State of)',
  },
  PRT: {
    title: 'Portugal',
  },
  PLW: {
    title: 'Palau',
  },
  PRY: {
    title: 'Paraguay',
  },
  QAT: {
    title: 'Qatar',
  },
  REU: {
    title: 'Réunion',
  },
  ROU: {
    title: 'Romania',
  },
  SRB: {
    title: 'Serbia',
  },
  RUS: {
    title: 'Russian Federation (the)',
  },
  RWA: {
    title: 'Rwanda',
  },
  SAU: {
    title: 'Saudi Arabia',
  },
  SLB: {
    title: 'Solomon Islands',
  },
  SYC: {
    title: 'Seychelles',
  },
  PAK: {
    title: 'Pakistan',
  },
  POL: {
    title: 'Poland',
  },
  SPM: {
    title: 'Saint Pierreand Miquelon',
  },
  PCN: {
    title: 'Pitcairn',
  },
  PRI: {
    title: 'Puerto Rico',
  },
  SDN: {
    title: 'Sudan (the)',
  },
  SWE: {
    title: 'Sweden',
  },
  SGP: {
    title: 'Singapore',
  },
  SHN: {
    title: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  SVN: {
    title: 'Slovenia',
  },
  SJM: {
    title: 'Svalbard and Jan Mayen',
  },
  SVK: {
    title: 'Slovakia',
  },
  SLE: {
    title: 'Sierra Leone',
  },
  SMR: {
    title: 'San Marino',
  },
  SEN: {
    title: 'Senegal',
  },
  SOM: {
    title: 'Somalia',
  },
  SUR: {
    title: 'Suriname',
  },
  SSD: {
    title: 'South Sudan',
  },
  STP: {
    title: 'São Tomé and Príncipe',
  },
  SLV: {
    title: 'El Salvador',
  },
  SXM: {
    title: 'Sint Maarten (Dutchpart)',
  },
  SWZ: {
    title: 'Eswatini',
  },
  TCA: {
    title: 'Turks and Caicos Islands (the)',
  },
  TCD: {
    title: 'Chad',
  },
  ATF: {
    title: 'French Southern Territories (the)',
  },
  TGO: {
    title: 'Togo',
  },
  THA: {
    title: 'Thailand',
  },
  TJK: {
    title: 'Tajikistan',
  },
  TKL: {
    title: 'Tokelau',
  },
  TLS: {
    title: 'Timor-Leste',
  },
  TKM: {
    title: 'Turkmenistan',
  },
  TUN: {
    title: 'Tunisia',
  },
  TON: {
    title: 'Tonga',
  },
  TUR: {
    title: 'Turkey',
  },
  TTO: {
    title: 'Trinidad and Tobago',
  },
  TUV: {
    title: 'Tuvalu',
  },
  TWN: {
    title: 'Taiwan (Province of China)',
  },
  TZA: {
    title: 'Tanzania',
  },
  UKR: {
    title: 'Ukraine',
  },
  UGA: {
    title: 'Uganda',
  },
  UMI: {
    title: 'United States Minor Outlying Islands',
  },
  USA: {
    title: 'United States of America',
  },
  URY: {
    title: 'Uruguay',
  },
  UZB: {
    title: 'Uzbekistan',
  },
  VAT: {
    title: 'Holy See (the)',
  },
  VCT: {
    title: 'Saint Vincent and the Grenadines',
  },
  VEN: {
    title: 'Venezuela',
  },
  VGB: {
    title: 'Virgin Islands (British)',
  },
  VIR: {
    title: 'Virgin Islands (U.S.)',
  },
  VNM: {
    title: 'Vietnam',
  },
  VUT: {
    title: 'Vanuatu',
  },
  WLF: {
    title: 'Wallis and Futuna',
  },
  WSM: {
    title: 'Samoa',
  },
  YEM: {
    title: 'Yemen',
  },
  MYT: {
    title: 'Mayotte',
  },
  ZAF: {
    title: 'South Africa',
  },
  ZMB: {
    title: 'Zambia',
  },
  LBN: {
    title: 'Lebanon',
  },
};
export const formatData = (countries: Array<CountrySearchResponseItem>) => {
  if (countries) {
    const countriesData = Object.fromEntries(
      countries.map((country: any) => [
        country.alphaThreeCountryCode,
        { title: country.countryName },
      ]),
    );

    Object.keys(countriesData).forEach((countryCode: string) => {
      countriesData[countryCode].code = countryCode;
      //TODO: remove "inputCountries" const once the api implements isEmbargoed flag.
      if (inputCountries[countryCode] !== undefined) {
        if (inputStates[countryCode]) {
          inputStates[countryCode].forEach((stateObject) => {
            if (countriesData[countryCode] && countriesData[countryCode].states) {
              countriesData[countryCode].states?.push({
                title: stateObject.state,
                code: stateObject.state.toLocaleUpperCase(),
                abbreviations: inputStateabbreviations[stateObject.state]?.abbreviations,
              });
            } else {
              countriesData[countryCode].states = [
                { title: stateObject.state, code: stateObject.state.toLocaleUpperCase() },
              ];
            }
          });
        }
      } else {
        delete countriesData[countryCode];
      }
    });
    return countriesData;
  }
};

export default formatData;
