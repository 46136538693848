import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  cell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
  },
  expandedRow: {
    backgroundColor: CAT_COLOR.ALABASTER,
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    bottom: '0px',
  },
  expandedCell: {
    marginLeft: theme.spacing(7),
  },
}));
