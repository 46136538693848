import React from 'react';
import { Box, TableCell, Typography } from '@mui/material';

export type HeaderRowType = {
  columns: any;
  expandedRowFlag?: boolean;
  scrollWidth?: number | undefined;
};

const HeaderRow = (props: HeaderRowType) => {
  const { columns, expandedRowFlag, scrollWidth } = props;

  return (
    <Box
      component="div"
      style={{
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        width: '100%',
        paddingRight: scrollWidth ? scrollWidth + 'px' : '0px',
      }}
    >
      {expandedRowFlag && (
        <TableCell
          component="div"
          style={{
            width: 0.025 * window.innerWidth,
            borderBottom: 'none',
          }}
        >
          <Box width={34} />
        </TableCell>
      )}
      {!expandedRowFlag && (
        <TableCell
          component="div"
          style={{
            width: 0,
            padding: '0px',
          }}
        ></TableCell>
      )}
      {columns.map((column: any) => {
        return (
          <TableCell
            component="div"
            key={column.name}
            variant="head"
            style={{
              width: column.width,
              height: 48,
              border: '1px solid transparent',
              padding: '8px',
              borderBottom: 'none',
              verticalAlign: 'middle',
            }}
            scope="col"
          >
            <Typography
              key={column.name}
              variant="body2"
              component="div"
              style={{ fontWeight: 600, fontSize: '12px' }}
            >
              {column.title}
            </Typography>
          </TableCell>
        );
      })}
    </Box>
  );
};

export default HeaderRow;
