import { Box, IconButton, TableCell, TableRow, useTheme } from '@mui/material';
import React, { memo, MouseEvent, ReactNode, RefObject, useEffect, useState } from 'react';
import { areEqual } from 'react-window';
import ResizeObserver from 'resize-observer-polyfill';

import { AppState } from '../../../../store';
import { useSelector } from 'react-redux';
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from '@mui/icons-material';
import useStyles from './styles';
import { PartyType } from '../../../../entities/entitlements-v1/partyType';

type RowPropertyTypes = {
  columns: Array<any>;
  rows: Array<any>;
  rowSizeList: number[];
  listReference: RefObject<any>;
  expandedRowFlag: boolean;
  nonExpandedRowHeight: number;
  expandedRowComponent: (item: any) => ReactNode;
  onClickOfRow?: (item: any, event: MouseEvent) => void;
  skipEntitlementCheck?: boolean;
  expandedRows: Array<number>;
  toggleItemActive: Function;
  autoSetRowHeight: Function;
  autoAdjustRowDetailHeight: boolean;
};

const Row = memo(
  ({ data, index, style }: { data: RowPropertyTypes; index: number; style: any }): JSX.Element => {
    const { classes } = useStyles();

    const {
      toggleItemActive,
      expandedRowComponent,
      rows,
      expandedRows,
      autoSetRowHeight,
      skipEntitlementCheck,
      onClickOfRow,
      expandedRowFlag,
      columns,
      listReference,
      rowSizeList,
      nonExpandedRowHeight,
      autoAdjustRowDetailHeight,
    } = data;
    const theme = useTheme();
    const item = rows[index];

    //TODO remove specific logic related to parties reponse
    const { partiesResponse } = useSelector((state: AppState) => state.login);
    const [isEntitledToEdit, setEntitledToEdit] = useState(false);

    const [rowResizeObserver, setRowResizeObserver] = useState<ResizeObserver>();

    const ref = React.useRef(null);

    useEffect(() => {
      const filteredParties = partiesResponse?.parties?.filter(
        (party) =>
          party.partyNumber === PartyType.CAT ||
          party.partyNumber === item?.customerOrganizationDetails?.owningDealerCode,
      );

      (filteredParties && filteredParties.length > 0) ||
      item?.customerOrganizationDetails?.hasAssociatedDealerCustomers
        ? setEntitledToEdit(true)
        : setEntitledToEdit(false);
    }, [item]);

    const onChange = (event: MouseEvent) => {
      event.stopPropagation();
      toggleItemActive(index);
    };

    useEffect(() => {
      if (expandedRows.includes(index)) {
        if (ref?.current) {
          rowResizeObserver && rowResizeObserver.observe(ref.current as any);
        }
      } else {
        rowResizeObserver && rowResizeObserver.disconnect();
      }
    }, [expandedRows]);
    useEffect(() => {
      autoAdjustRowDetailHeight &&
        setRowResizeObserver(
          new ResizeObserver(() => {
            if (ref.current) {
              autoSetRowHeight((ref.current as any).clientHeight, index);
            }
          }),
        );
      return () => {
        autoAdjustRowDetailHeight && rowResizeObserver?.disconnect();
      };
    }, []);

    return (
      <TableRow
        component="div"
        style={{
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          ...style,

          ...(onClickOfRow ? { cursor: 'pointer' } : {}),
        }}
        onClick={(event: MouseEvent) => (onClickOfRow ? onClickOfRow(item, event) : null)}
      >
        {expandedRowFlag && (
          <TableCell
            component="div"
            className={classes.cell}
            style={{
              width: 0.025 * window.innerWidth,
              borderBottom: 'none',
              minWidth: isEntitledToEdit ? 'auto' : theme.spacing(8),
            }}
          >
            {(skipEntitlementCheck || isEntitledToEdit) && (
              <IconButton
                aria-label="expand row"
                size="small"
                type="button"
                onClick={(event: MouseEvent) => {
                  onChange(event);
                }}
                data-testid="expand-collapse-icon-button"
              >
                {expandedRows.includes(index) ? (
                  <KeyboardArrowUpRounded />
                ) : (
                  <KeyboardArrowDownRounded />
                )}
              </IconButton>
            )}
          </TableCell>
        )}
        {/* {'TODO: Fix table cell width on higher resolution more than 2800px AB#868472'} */}
        {!expandedRowFlag && (
          <TableCell
            component="div"
            className={classes.cell}
            style={{
              width: 0.00025,
              borderBottom: 'none',
              minWidth: 'auto',
              padding: '0px',
            }}
          ></TableCell>
        )}
        {columns?.map((column: any) => {
          return (
            <TableCell
              key={column.name}
              component="div"
              scope="row"
              className={classes.cell}
              align={column.align || 'left'}
              style={{
                ...column.style,
                // eslint-disable-next-line unicorn/no-useless-spread
                ...{
                  width: column.width,
                  padding: theme.spacing(1),
                  height: nonExpandedRowHeight,

                  borderBottom: 'none',
                  fontSize: '14px',

                  border: '1px solid transparent',
                },
              }}
            >
              {item && column.getCellValue(item, index, listReference)}
            </TableCell>
          );
        })}

        {expandedRowFlag && expandedRows.includes(index) && (
          <div
            data-testid="multi-recommendation-expanded-row-detail-row"
            className={classes.expandedRow}
            ref={ref}
            style={{
              height: autoAdjustRowDetailHeight
                ? 'auto'
                : `${rowSizeList[index] - nonExpandedRowHeight}px`,
            }}
          >
            <TableCell
              component="div"
              colSpan={columns.length + 1}
              scope="row"
              style={{
                display: 'flex',
                paddingBottom: 0,
                height: autoAdjustRowDetailHeight
                  ? 'auto'
                  : rowSizeList[index] - nonExpandedRowHeight,
              }}
            >
              <Box width="100%">{expandedRowComponent(item)}</Box>
            </TableCell>
          </div>
        )}
      </TableRow>
    );
  },
  areEqual,
);

export default Row;
