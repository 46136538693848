import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import StepperButton from '../../../../components/stepper-button';
import TextWithToolTip from '../../../../components/text-with-tooltip';
import { OrganizationResponse } from '../../../../entities/customer-master-v1/organizationResponse';
import {
  AccessType,
  CustomerAssociationPartyDetails,
  Role,
  UpdateUserAccessRequestApproveAssociationRequest,
  UserAccessRequestResponse,
  UserAccessRequestUpdateApproveStatus,
  UserRequestType,
} from '../../../../entities/user-onboarding-v1/models';
import { INVITATION_APPLICATIONS } from '../../../../services/invitations';
import { useSelector } from 'react-redux';
import { PermissionsHashMapForUserBased } from '../../../../entities/entitlements-v1/permissionsHashMapForUserBased';
import { UserBasedPermission } from '../../../../entities/entitlements-v1/userBasedPermission';
import { PartyDetailsType } from '../../../../entities/user-onboarding-v1/partyDetailsType';
import AccessRequestsService from '../../../../services/access-requests';
import { UserAccessRequestStatus } from '../../../../entities/user-onboarding-v1/userAccessRequestStatus';
import { AppState, useDispatchTs } from '../../../../store';
import {
  accessRequestPermissionMethods,
  accessRequestPermissionName,
  AdditionalInfoType,
} from '../../../../store/access-request/types';
import { selectAccountsByPermissionType } from '../../../../store/permissions/selectors';
import { formatDate, showNotification } from '../../../../utils/util';
import AccessApprovalDetailsCard from '../access-approval-details-card';
import useStyles from './styles';
import Warning from '../../../../components/warning';
import CustomerAddressDisplay from '../../../../components/customer-address';
import { nameIdPair, nameIdPairArray } from '../../../../globals/generic-types';
import Loading from '../../../../components/loading';
import { Trans, useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';
import { displayFirstNameLastName } from '../../../../utils/util';
import { TFunction } from 'i18next';
import { ApplicationName } from '../../../../constants';
import { RoleType } from '../../../../store/invitations/types';

type RowDetailsPropertyType = {
  row: any;
  onClickOfDenyRequest: Function;
  refreshSearchResults: Function;
};

const accessRequestValidationSchema = (
  apptype: string,
  roles: Role[],
  t: TFunction<'translation', undefined>,
  isRestrictedParty: Function,
  row: UserAccessRequestResponse,
) => {
  return yup.object({
    selectedCustomers: yup
      .array()
      .of(yup.object())
      .min(1, t(TEXT.ACCESS_REQUESTS.VALIDATION_SCHEMA.ADD_COMPANY_REQUIRED)),
    selectedDealerCodes: yup.string().when('selectedCustomers', {
      is: (country: string) => country.length > 0,
      then: () =>
        yup
          .array()
          .test(
            'is-dcn-empty',
            t(TEXT.ACCESS_REQUESTS.VALIDATION_SCHEMA.ADD_DEALER_CUSTOMERS_REQUIRED),
            (selectedDealerCodes) =>
              apptype === INVITATION_APPLICATIONS.VISION_LINK ||
              ((selectedDealerCodes && selectedDealerCodes.length > 0) as boolean),
          ),
      otherwise: () =>
        yup
          .array()
          .test(
            'is-dcn-empty',
            t(
              TEXT.ACCESS_REQUESTS.VALIDATION_SCHEMA
                .ADD_COMPANY_REQUIRED_TO_SELECT_DEALER_CUSTOMERS,
            ),
            (selectedCustomers) =>
              apptype === INVITATION_APPLICATIONS.VISION_LINK ||
              ((selectedCustomers && selectedCustomers.length > 0) as boolean),
          ),
    }),
    visionLinkRole: yup.string().when('selectedCustomers', {
      is: (country: string) => country.length > 0,
      then: () =>
        yup
          .string()
          .test(
            'is-empty',
            t(TEXT.ACCESS_REQUESTS.VALIDATION_SCHEMA.SELECT_ROLE_REQUIRED),
            (visionLinkRole, context) =>
              (apptype === INVITATION_APPLICATIONS.PCC ||
                apptype === INVITATION_APPLICATIONS.NONPRODPCC ||
                (visionLinkRole && visionLinkRole.length > 0) ||
                (isRestrictedParty(context.parent.selectedCustomers) &&
                  row.status !== UserAccessRequestStatus.DENIED)) as boolean,
          ),
      otherwise: () =>
        yup
          .string()
          .test(
            'is-empty',
            t(TEXT.ACCESS_REQUESTS.VALIDATION_SCHEMA.ADD_COMPANY_REQUIRED_TO_SELECT_ROLE),
            (visionLinkRole) =>
              (apptype === INVITATION_APPLICATIONS.PCC ||
                apptype === INVITATION_APPLICATIONS.NONPRODPCC ||
                (visionLinkRole && visionLinkRole.length > 0)) as boolean,
          ),
    }),
  });
};

export const getAccessRequestType = (row: UserAccessRequestResponse): string => {
  let applicationId = '';
  switch (row?.application?.applicationId) {
    case INVITATION_APPLICATIONS.VISION_LINK:
      applicationId = INVITATION_APPLICATIONS.VISION_LINK;
      break;
    case INVITATION_APPLICATIONS.PCC:
      applicationId = INVITATION_APPLICATIONS.PCC;
      break;
    case INVITATION_APPLICATIONS.NONPRODPCC:
      applicationId = INVITATION_APPLICATIONS.NONPRODPCC;
      break;
  }
  return applicationId;
};

const RowDetails = (props: RowDetailsPropertyType) => {
  const { row, onClickOfDenyRequest, refreshSearchResults } = props;
  const accessRequestVisionLinkRoleType = row.application.accessIdentifiers;

  const { authInfo, environmentVariables } = useSelector((state: AppState) => state.login);
  const { applicationRoles, applications } = useSelector(
    (state: AppState) => state.shared.metadata,
  );
  const { filters } = useSelector((state: AppState) => state.accessRequest);

  const theme = useTheme();
  const { classes } = useStyles();
  const dispatch = useDispatchTs();
  const [showApproveWarning, setShowApproveWarning] = useState(false);
  const [updateReqestLoading, setUpdateReqestLoading] = useState(false);
  const { t } = useTranslation();

  const { permissionsResponse } = useSelector((state: AppState) => state.login);
  const updatePermission =
    selectAccountsByPermissionType(
      (permissionsResponse as UserBasedPermission)
        ?.dataPermissions as PermissionsHashMapForUserBased,
      accessRequestPermissionName,
      accessRequestPermissionMethods.update,
    ).length > 0;

  const exactMatch = row.partyDetails?.find(
    (party: CustomerAssociationPartyDetails) => party.type === PartyDetailsType.EXACTMATCH,
  );
  const userEntered = row.partyDetails?.find(
    (party: CustomerAssociationPartyDetails) => party.type === PartyDetailsType.USERENTERED,
  );
  const selectedMatch = row.partyDetails?.find(
    (party: CustomerAssociationPartyDetails) => party.type === PartyDetailsType.SELECTEDMATCH,
  );

  const additionalInfoSerialNumbers =
    row.additionalInfo &&
    row.additionalInfo.find((item: any) => item.key === AdditionalInfoType.SERIAL_NUMBERS) &&
    row.additionalInfo
      .find((item: any) => item.key === AdditionalInfoType.SERIAL_NUMBERS)
      .values.join(' ');
  const additionalInfoDcn =
    row.additionalInfo &&
    row.additionalInfo.find(
      (item: any) => item.key === AdditionalInfoType.DEALER_CUSTOMER_NUMBERS,
    ) &&
    row.additionalInfo
      .find((item: any) => item.key === AdditionalInfoType.DEALER_CUSTOMER_NUMBERS)
      .values.join(' ');

  const submitApproveRequest = async () => {
    let inputReq: UpdateUserAccessRequestApproveAssociationRequest = {
      applications: [
        {
          applicationId: getAccessRequestType(row) as string,
          accessType:
            getAccessRequestType(row) === INVITATION_APPLICATIONS.VISION_LINK
              ? AccessType.ACCESSLEVEL
              : AccessType.DCI,
          accessIdentifiers:
            getAccessRequestType(row) === INVITATION_APPLICATIONS.VISION_LINK
              ? [
                  formik.values.visionLinkRole ||
                    applicationRoles.find((role) => role.roleName === RoleType.READ_ONLY)?.roleId,
                ]
              : formik.values.selectedDealerCodes.map((dealerCode: nameIdPair) => dealerCode.id),
        },
      ],
      status: UserAccessRequestUpdateApproveStatus.APPROVED,
      partyNumber:
        formik.values?.selectedCustomers[0]?.customerOrganizationDetails
          ?.customerOrganizationIdentifier || '',
    };

    const accessRequestService = new AccessRequestsService(
      authInfo,
      environmentVariables.userOnboardingV1,
    );

    try {
      setUpdateReqestLoading(true);
      await accessRequestService.editAccessRequest(
        row.requestId as string,
        inputReq,
        filters?.selectedAccount,
      );
      showNotification(
        dispatch,
        'success',
        t(TEXT.ACCESS_REQUESTS_SERVICE.NOTIFICATIONS.EDIT_ACCESS_REQUEST_APPROVED),
      );
      refreshSearchResults();
      if (row?.application?.applicationId !== INVITATION_APPLICATIONS.VISION_LINK) {
        showNotification(
          dispatch,
          'info',
          <Box display="flex" flexDirection="column">
            <Trans
              i18nKey={TEXT.ACCESS_REQUESTS_SERVICE.NOTIFICATIONS.EDIT_ACCESS_REQUEST_REFRESHING}
              components={[<span className={classes.bold} />]}
            />
          </Box>,
          {
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            className: classes.infoLarger,
            autoHideDuration: 10000,
          },
        );
      }
      setUpdateReqestLoading(false);
    } catch {
      showNotification(
        dispatch,
        'error',
        t(TEXT.ACCESS_REQUESTS_SERVICE.ERRORS.EDIT_ACCESS_REQUEST_APPROVING),
      );
      setUpdateReqestLoading(false);
    }
  };

  let initialValues = {
    selectedCustomers: [] as Array<OrganizationResponse>,
    selectedDealerCodes: row.application.accessIdentifiers || ([] as nameIdPairArray),
    visionLinkRole:
      (accessRequestVisionLinkRoleType !== undefined && accessRequestVisionLinkRoleType[0]) || '',
  };

  if (selectedMatch) {
    const customers = {
      customerOrganizationDetails: {
        customerOrganizationBusinessName: selectedMatch.name,
        customerOrganizationIdentifier: selectedMatch.partyNumber,
      },
    };
    initialValues.selectedCustomers.push(customers);
  }
  if (row.type === UserRequestType.ASSOCIATIONREQUEST && exactMatch) {
    const customers = {
      customerOrganizationDetails: {
        customerOrganizationBusinessName: exactMatch.name,
        customerOrganizationIdentifier: exactMatch.partyNumber,
      },
    };
    initialValues.selectedCustomers.push(customers);
  }
  if (row.type === UserRequestType.APPLICATIONACCESS) {
    const customers = {
      customerOrganizationDetails: {
        customerOrganizationBusinessName:
          (row.primaryPartyDetails && row.primaryPartyDetails.partyName) || '',
        customerOrganizationIdentifier:
          (row.primaryPartyDetails && row.primaryPartyDetails.partyNumber) || '',
      },
    };
    initialValues.selectedCustomers.push(customers);
  }

  const typeAssociationReqest = row.type === UserRequestType.ASSOCIATIONREQUEST;
  const typeApplicationAccess = row.type === UserRequestType.APPLICATIONACCESS;
  const checkApprovedProcessingFailed =
    row.status === UserAccessRequestStatus.APPROVED ||
    row.status === UserAccessRequestStatus.PROCESSING ||
    row.status === UserAccessRequestStatus.FAILED;
  const checkPendingDenied =
    row.status === UserAccessRequestStatus.PENDING || UserAccessRequestStatus.DENIED;

  const companyName = () => {
    if (typeAssociationReqest) {
      if (checkApprovedProcessingFailed) {
        return row?.primaryPartyDetails?.partyName || '';
      } else if (!checkApprovedProcessingFailed && formik.values.selectedCustomers.length > 0) {
        return (
          formik.values?.selectedCustomers[0].customerOrganizationDetails
            ?.customerOrganizationBusinessName || ''
        );
      } else if (row?.partyDetails && !checkPendingDenied) {
        return row?.partyDetails[0].name || '';
      } else return '';
    } else if (typeApplicationAccess) {
      return row?.primaryPartyDetails?.partyName || '';
    } else return '';
  };

  const companyId = (selectedCustomer: Array<OrganizationResponse>): string => {
    if (typeAssociationReqest) {
      if (checkApprovedProcessingFailed) {
        return row?.primaryPartyDetails?.partyNumber || '';
      } else if (!checkApprovedProcessingFailed && selectedCustomer?.length > 0) {
        return (
          selectedCustomer[0].customerOrganizationDetails?.customerOrganizationIdentifier || ''
        );
      } else if (row?.partyDetails && !checkPendingDenied) {
        return row?.partyDetails[0].partyNumber || '';
      } else return '';
    } else if (typeApplicationAccess) {
      return row?.primaryPartyDetails?.partyNumber || '';
    } else return '';
  };

  const restrictedPartiesList =
    applications.find((application) => application.applicationName === ApplicationName.VISIONLINK)
      ?.restrictedParties || [];

  const isRestrictedParty = (selectedCustomer: Array<OrganizationResponse>) =>
    environmentVariables.restrictedAccountsFlag &&
    restrictedPartiesList.includes(companyId(selectedCustomer) ?? '') &&
    environmentVariables.wasteManagementCcid === companyId(selectedCustomer);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: accessRequestValidationSchema(
      getAccessRequestType(row),
      applicationRoles,
      t,
      isRestrictedParty,
      row,
    ),
    onSubmit: async () => {
      if (row.status === UserAccessRequestStatus.DENIED) {
        setShowApproveWarning(true);
      } else {
        submitApproveRequest();
      }
    },
  });

  const isCompanyDataEditable = () => {
    if (typeAssociationReqest) {
      if (checkApprovedProcessingFailed) {
        return false;
      } else return true;
    } else if (typeApplicationAccess) {
      return false;
    } else return true;
  };

  const loadingFlag = updateReqestLoading;
  return (
    <Box data-testid="access-requests-expanded-row-details-container" py={theme.spacing(1.6)}>
      {loadingFlag && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="70%"
          width="100%"
          position={'absolute'}
        >
          <Loading height={80} width={90} display={'block'} blocks={true} />
        </Box>
      )}
      <Box className={classes.gridContainer}>
        <Grid container px={7}>
          <Grid item xs={4} maxWidth={theme.spacing(38)} px={0.5}>
            <Box mb={3}>
              <Typography>{t(TEXT.ACCESS_REQUESTS.ROW_DETAILS.DATE_REQUESTED)}</Typography>
              <Typography className={classes.bold}>{formatDate(row.createdTime)}</Typography>
            </Box>
            <Box mb={3}>
              <Typography>{t(TEXT.COMMON.TITLE.NAME)}</Typography>
              <TextWithToolTip
                longText={displayFirstNameLastName(row.firstName, row.lastName)}
                bold
                fontSize="14px"
                maxWidth="90%"
              ></TextWithToolTip>
            </Box>
            <Box mb={3}>
              <Typography>{t(TEXT.ACCESS_REQUESTS.ROW_DETAILS.USERNAME)}</Typography>
              <Typography className={classes.bold}>{row.username || '--'}</Typography>
            </Box>
            <Box mb={3}>
              <Typography>{t(TEXT.COMMON.TITLE.EMAIL)}</Typography>
              <Typography className={classes.bold}>{row.email || '--'}</Typography>
            </Box>
            <Box mb={3}>
              <Typography>{t(TEXT.ACCESS_REQUESTS.ROW_DETAILS.PHONE)}</Typography>
              <Typography className={classes.bold}>{row.phoneNumber || '--'}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} maxWidth={theme.spacing(38)} px={0.5}>
            <Box mb={3}>
              <Typography>{t(TEXT.ACCESS_REQUESTS.ROW_DETAILS.REGISTRATION_FORM_INPUT)}</Typography>
            </Box>
            {userEntered && (
              <Box mb={3}>
                <Typography fontStyle="italic">
                  {t(TEXT.ACCESS_REQUESTS.ROW_DETAILS.USER_ENTRY)}
                </Typography>
                <TextWithToolTip
                  longText={userEntered.name}
                  bold
                  fontSize="14px"
                  maxWidth="90%"
                ></TextWithToolTip>
                <CustomerAddressDisplay
                  address1={userEntered.addressLine1}
                  address2={userEntered.addressLine2}
                  cityName={userEntered.city}
                  stateOrProvinceCode={userEntered.stateProvince}
                  countryCode={userEntered.country}
                  postalCode={userEntered.postalZipCode}
                />
              </Box>
            )}
            {exactMatch && (
              <Box mb={3}>
                <Typography fontStyle="italic">
                  {t(TEXT.ACCESS_REQUESTS.ROW_DETAILS.EXACT_MATCH)}
                </Typography>
                <TextWithToolTip
                  longText={exactMatch.name}
                  bold
                  fontSize="14px"
                  maxWidth="90%"
                ></TextWithToolTip>
                <Box display="flex" flexDirection="row">
                  <Typography className={classes.bold} mr={0.5}>
                    {t(TEXT.COMMON.ID)}:
                  </Typography>
                  <Typography>{exactMatch.partyNumber}</Typography>
                </Box>
                <CustomerAddressDisplay
                  address1={exactMatch.addressLine1}
                  address2={exactMatch.addressLine2}
                  cityName={exactMatch.city}
                  stateOrProvinceCode={exactMatch.stateProvince}
                  countryCode={exactMatch.country}
                  postalCode={exactMatch.postalZipCode}
                />
              </Box>
            )}
            {selectedMatch && (
              <Box mb={3}>
                <Typography fontStyle="italic">
                  {t(TEXT.ACCESS_REQUESTS.ROW_DETAILS.SELECTED_MATCH)}
                </Typography>
                <TextWithToolTip
                  longText={selectedMatch.name}
                  bold
                  fontSize="14px"
                  maxWidth="90%"
                ></TextWithToolTip>
                <Box display="flex" flexDirection="row">
                  <Typography className={classes.bold} mr={0.5}>
                    {t(TEXT.COMMON.ID)}
                  </Typography>
                  <Typography>{selectedMatch.partyNumber}</Typography>
                </Box>
                <CustomerAddressDisplay
                  address1={selectedMatch.addressLine1}
                  address2={selectedMatch.addressLine2}
                  cityName={selectedMatch.city}
                  stateOrProvinceCode={selectedMatch.stateProvince}
                  countryCode={selectedMatch.country}
                  postalCode={selectedMatch.postalZipCode}
                />
              </Box>
            )}
            {additionalInfoSerialNumbers && (
              <Box mb={3}>
                <Typography>
                  {t(TEXT.ACCESS_REQUESTS.ROW_DETAILS.PROVIDED_SERIAL_NUMBERS)}
                </Typography>
                <Typography className={classes.bold}>
                  {additionalInfoSerialNumbers || '--'}
                </Typography>
              </Box>
            )}
            {additionalInfoDcn && (
              <Box mb={3}>
                <Typography>{t(TEXT.ACCESS_REQUESTS.ROW_DETAILS.DCN)}</Typography>
                <Typography className={classes.bold}>{additionalInfoDcn || '--'}</Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={4} pl={theme.spacing(6)}>
            <AccessApprovalDetailsCard
              formikProps={formik}
              row={row}
              header={t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.ACCESS_APPROVAL_DETAILS)}
              companyName={companyName}
              companyId={companyId}
              isCompanyDataEditable={isCompanyDataEditable}
              isRestrictedParty={isRestrictedParty(formik.values.selectedCustomers)}
              checkApprovedProcessingFailed={checkApprovedProcessingFailed}
            />
          </Grid>
        </Grid>
      </Box>

      {updatePermission &&
        (row.status === UserAccessRequestStatus.PENDING ||
          row.status === UserAccessRequestStatus.DENIED) && (
          <>
            <Divider className={classes.divider} />

            <Box className={classes.footer} data-testid="access-request-expanded-row-footer">
              {row.status === UserAccessRequestStatus.PENDING && (
                <Box mr={2}>
                  <StepperButton
                    buttonText={t(TEXT.COMMON.DENY)}
                    id="deny-request-button"
                    color="error"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onClickOfDenyRequest();
                      return;
                    }}
                    type="button"
                  />
                </Box>
              )}
              {(row.status === UserAccessRequestStatus.PENDING ||
                row.status === UserAccessRequestStatus.DENIED) && (
                <Box mr={2}>
                  <StepperButton
                    disabled={!formik.isValid}
                    buttonText={t(TEXT.COMMON.APPROVE)}
                    id="approve-request-button"
                    color="primary"
                    onClick={() => formik.submitForm()}
                    type="button"
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      {showApproveWarning && (
        <Warning
          open={true}
          setOpen={() => setShowApproveWarning(false)}
          messageText={t(TEXT.ACCESS_REQUESTS.WARNING, { username: row?.username || '' })}
          primaryCallback={() => submitApproveRequest()}
          titleText={t(TEXT.COMMON.WARNING)}
          primaryText={t(TEXT.COMMON.CONTINUE)}
          secondaryText={t(TEXT.COMMON.CANCEL)}
          maxWidth="xs"
        />
      )}
    </Box>
  );
};

export default RowDetails;
