import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import { OAuth2Client, CodeVerificationStrategy, TokenInformation } from '@kolorado/oauth';
import Authentication from '../authentication';
import { AppState, useDispatchTs } from '../../store';
import { AuthInfo } from '../../model/types';
import { loginActionNames } from '../../store/login/types';
import Entitlements from '../../services/entitlements';
import DealerCode, { DealerCodeSearchResponse } from '../../services/dealer-code';
import { showNotification } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import TEXT from '../translation-map';

type AuthLayerProperties = {
  children: any;
};
const AuthLayer = (properties: AuthLayerProperties) => {
  const { t } = useTranslation();
  const { children } = properties;
  const { authInfo, environmentVariables, permissionsUpdateFlag, updateToken } = useSelector(
    (state: AppState) => state.login,
  );
  const [open, setOpen] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const logoutUser = () => {
    window.localStorage.removeItem('saved-path');
    window.location.href = environmentVariables.logoutURI;
  };
  const dispatch = useDispatchTs();

  const client = OAuth2Client.of({
    uri: environmentVariables.authURI,
    tokenURI: environmentVariables.tokenURI,
    clientID: 'CustomerMasterUI_ac_client',
    redirectURI: environmentVariables.redirectURI,
    antiForgeryTtl: environmentVariables.antiForgeryTtl,
    proofCodeTtl: environmentVariables.proofCodeTtl,
    leniency: environmentVariables.leniency,
    oauthAdapter: 'OAuthAdapterCCDS',
    responseType: 'code',
    codeVerification: CodeVerificationStrategy.SYNCHRONIZED,
  });

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          if (open) logoutUser();
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  const handleClickOpen = () => {
    setOpen(true);
    setMinutes(4);
    setSeconds(59);
  };

  const handleClose = () => {
    setOpen(false);
    window.localStorage.setItem('session-refreshed', 'true');
  };
  const refreshAuthToken = useCallback(() => {
    if (authInfo && authInfo.refreshToken) {
      client
        .refresh(authInfo.refreshToken || '')
        .then((tokenInformation: TokenInformation) => {
          const {
            loginId,
            metadata: { catrecid, client_id: clientId, catafltncode },
            token,
            refreshToken,
            expiresIn,
          } = tokenInformation;
          const authInfoDispatch: AuthInfo = {
            loginId,
            catrecid,
            clientId,
            token,
            catafltncode,
            refreshToken,
            expiresIn,
          };
          dispatch({
            type: loginActionNames.SET_LOGIN_TIME,
            payload: Date.now(),
          });
          return dispatch({ type: loginActionNames.SET_CLIENT, payload: authInfoDispatch });
        })
        .catch((error: any) => {
          return dispatch({ type: loginActionNames.SET_LOGIN_ERROR, payload: error });
        });
      handleClose();
    }
  }, [authInfo, dispatch]);

  useEffect(() => {
    const fetchPermissions = async () => {
      const entitlementService = new Entitlements(
        authInfo?.token,
        environmentVariables.entitlementsV1,
      );
      const permissionsResponse = await entitlementService.getUserPermissions({
        includeAppPermissions: 'true',
      });
      if (permissionsResponse.status === 200 && permissionsResponse.data) {
        dispatch({
          type: loginActionNames.SET_PERMISSIONS_RESPONSE,
          payload: permissionsResponse.data,
        });
        dispatch({
          type: loginActionNames.SET_PERMISSIONS_UPDATE_FLAG,
          payload: false,
        });
      }

      try {
        if (
          permissionsResponse.data.dataPermissions &&
          permissionsResponse.data.dataPermissions?.customerMaster.create
        ) {
          const partyNumbersWithCreateCustomerPermission = (permissionsResponse.data.dataPermissions
            ?.customerMaster.create.filterConditions || [])[0].partyNumbers;
          if (partyNumbersWithCreateCustomerPermission.includes('CAT')) {
            dispatch({
              type: loginActionNames.SET_INTEGRATED_DEALER_FLG,
              payload: true,
            });
            dispatch({
              type: loginActionNames.SET_INTEGRATED_DEALER_CODES,
              payload: ['CAT'],
            });
          } else {
            const dealerCodeService = new DealerCode(
              authInfo,
              environmentVariables.customerMasterURI,
            );
            const dealerCodeSearchResponse = await dealerCodeService.searchDealerCodes({
              body: {
                logicalExpression: '$0 & $1',
                filters: [
                  {
                    propertyName: 'dealerCode',
                    type: 'stringEquals',
                    values: [...partyNumbersWithCreateCustomerPermission],
                  },
                  {
                    propertyName: 'isIntegrated',
                    type: 'booleanEquals',
                    value: true,
                  },
                ],
              },
            });
            const dealerCodes = (dealerCodeSearchResponse.data as DealerCodeSearchResponse)
              .dealerCodes;
            if (dealerCodes.length > 0) {
              dispatch({
                type: loginActionNames.SET_INTEGRATED_DEALER_CODES,
                payload: dealerCodes,
              });
              dispatch({
                type: loginActionNames.SET_INTEGRATED_DEALER_FLG,
                payload: true,
              });
            } else {
              dispatch({
                type: loginActionNames.SET_INTEGRATED_DEALER_FLG,
                payload: false,
              });
            }
          }
        }
      } catch {
        dispatch({
          type: loginActionNames.SET_INTEGRATED_DEALER_FLG,
          payload: false,
        });
        showNotification(
          dispatch,
          'error',
          t(TEXT.MY_CUSTOMERS.ASSOCIATE_DEALER_CUSTOMER.ERRORS.SEARCH_DEALER_CUSTOMER),
        );
      }

      const partiesResponse = await entitlementService.getParties();
      if (partiesResponse.status === 200 && partiesResponse.data) {
        dispatch({
          type: loginActionNames.SET_PARTIES_RESPONSE,
          payload: partiesResponse.data,
        });
      }
      dispatch({
        type: loginActionNames.SET_PERMISSIONS_FETCHING,
        payload: false,
      });
    };
    if (permissionsUpdateFlag) {
      dispatch({
        type: loginActionNames.SET_PERMISSIONS_FETCHING,
        payload: true,
      });
      fetchPermissions();
    }
  }, [authInfo, dispatch, environmentVariables.entitlementsV1, permissionsUpdateFlag]);

  useEffect(() => {
    if (updateToken) {
      refreshAuthToken();
      dispatch({
        type: loginActionNames.SET_UPDATE_TOKEN_FLAG,
        payload: false,
      });
    }
  }, [dispatch, refreshAuthToken, updateToken]);

  useEffect(() => {
    if (authInfo === null && environmentVariables.redirectURI) {
      Authentication(window.location.search, client, dispatch, environmentVariables);
      if (window.localStorage.getItem('fedlogin-complete') === 'true') {
        window.localStorage.setItem('fedlogin-complete', 'false');
      } else {
        window.localStorage.setItem('fedlogin-complete', 'true');
      }
    } else if (
      authInfo &&
      authInfo.refreshToken &&
      authInfo.expiresIn &&
      !environmentVariables.refreshSession
    ) {
      setTimeout(
        () => {
          handleClickOpen();
        },
        (authInfo.expiresIn - 300) * 1000,
      );
    }
  }, [authInfo, dispatch, environmentVariables, permissionsUpdateFlag]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Session Expiration</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your session will expire in{' '}
            {minutes.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:
            {seconds.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}{' '}
            minutes, please press continue or refresh the page or login/logout soon.
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={refreshAuthToken} variant="contained" color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      {children}
    </>
  );
};
export default AuthLayer;
