import { Box, Typography } from '@mui/material';
import React, { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
import useStyles from './styles';
import { AppState, useDispatchTs } from '../../../../store';
import Stroke from '../../../../assets/icons/stroke';
import {
  PatchUserAddUpdateEntitlementCatSuperAdminInput,
  PatchUserAddUpdateEntitlementNonCat,
} from '../../../../entities/user-management-v1/models';
import UserManagement from '../../../../services/user-management';
import { loginActionNames } from '../../../../store/login/types';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import TEXT from '../../../../globals/translation-map';
import { showNotification } from '../../../../utils/util';

type ChangeAccountProperties = {
  setRef?: RefObject<HTMLDivElement> | null;
  closeProfileOptions?: () => void;
};
const ChangeAccount = (props: ChangeAccountProperties) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatchTs();
  const navigate = useNavigate();
  const { setRef = null, closeProfileOptions = () => {} } = props;
  const { authInfo, environmentVariables, partiesResponse } = useSelector(
    (state: AppState) => state.login,
  );
  const parties = (partiesResponse?.parties || []).sort((party1) =>
    party1.partyNumber !== 'APPSUPP' ? -1 : 1,
  );

  const selectAccount = async (partiesResponseSelectAccount: any) => {
    if (partiesResponseSelectAccount && partiesResponseSelectAccount?.parties) {
      try {
        const userManagement = new UserManagement(authInfo, environmentVariables.userManagementV1);
        const nonPrimaryParty = partiesResponseSelectAccount?.parties.find((item: any) => {
          return item.partyNumber !== 'APPSUPP';
        });
        const newInput: PatchUserAddUpdateEntitlementCatSuperAdminInput = {
          partyNumber: nonPrimaryParty?.partyNumber,
          roleIds: [],
        };
        const removeEntitlementNonCatInput: PatchUserAddUpdateEntitlementNonCat = {
          entitlements: [newInput],
        };
        if (closeProfileOptions) closeProfileOptions();

        if (authInfo?.catrecid) {
          const updateCatUserResponse = await userManagement.updateCatUser(
            removeEntitlementNonCatInput,
            authInfo?.catrecid,
          );
          if (updateCatUserResponse.status === 200) {
            showNotification(
              dispatch,
              'success',
              i18n.t(TEXT.USER_MANAGEMENT.NOTIFICATIONS.ACCOUNT_CHANGE),
            );
            dispatch({
              type: loginActionNames.SET_PERMISSIONS_UPDATE_FLAG,
              payload: true,
            });
            dispatch({
              type: loginActionNames.SET_UPDATE_TOKEN_FLAG,
              payload: true,
            });
            navigate('/admin/manage-access');
          } else {
            showNotification(dispatch, 'error', i18n.t(TEXT.USER_MANAGEMENT.ERRORS.USER_UPDATING));
          }
        }
      } catch {
        showNotification(dispatch, 'error', i18n.t(TEXT.USER_MANAGEMENT.ERRORS.USER_UPDATING));
      }
    }
  };
  return (
    <div
      ref={setRef}
      data-testid="change-account-component"
      id="change-account"
      className={classes.user}
    >
      <Box>
        <Typography className={classes.title} variant="h4">
          {t(TEXT.COMMON.CHANGE_ACCOUNT)}
        </Typography>
      </Box>
      {parties.map((accountItem: any) => {
        const isPrimaryAndSinglePartyItem =
          accountItem.isPrimary &&
          accountItem.partyNumber === 'APPSUPP' &&
          partiesResponse &&
          partiesResponse?.parties &&
          partiesResponse?.parties.length === 1;

        const isPrimaryAndMultiplePartyItems =
          accountItem.isPrimary &&
          accountItem.partyNumber === 'APPSUPP' &&
          partiesResponse &&
          partiesResponse?.parties &&
          partiesResponse?.parties.length > 1;
        return (
          <Box
            key={shortid.generate()}
            onClick={() => {
              if (
                accountItem.isPrimary &&
                partiesResponse?.parties &&
                partiesResponse?.parties.length > 1
              ) {
                selectAccount(partiesResponse);
              } else if (closeProfileOptions) closeProfileOptions();
            }}
            className={
              isPrimaryAndMultiplePartyItems ? classes.primaryAccount : classes.selectedSection
            }
          >
            <Typography
              className={
                isPrimaryAndMultiplePartyItems ? classes.primaryName : classes.selectedName
              }
              variant="h5"
            >
              {(isPrimaryAndSinglePartyItem ||
                (accountItem.partyNumber !== 'APPSUPP' &&
                  partiesResponse &&
                  partiesResponse?.parties &&
                  partiesResponse?.parties.length > 1)) && <Stroke width="20" height="20" />}
              {`${accountItem.roles[0].roleName} (${accountItem.partyNumber})`}
            </Typography>
          </Box>
        );
      })}
    </div>
  );
};

export default ChangeAccount;
