import React, { Dispatch, SetStateAction } from 'react';
import Box from '@mui/material/Box';
import { ClassNameMap, Link } from '@mui/material';
import TextWithToolTip from '../../../../components/text-with-tooltip';
import { AssetRecommendationType } from '../../../../entities/customerAssetWorkflow-v1/assetRecommendationType';
import dayjs from 'dayjs';
import ColumnSort from '../../../../components/column-sort';
import { AssetRecommendationSortingProperties } from '../../../../entities/customerAssetWorkflow-v1/assetRecommendationSortingProperties';
import SortLabelComponent from '../../../../components/sort-label';
import CAT_COLOR from '../../../../globals/color-properties';
import i18n from 'i18next';
import TEXT from '../../../../globals/translation-map';

const AssetRecommendationColumns = (options: {
  classes: ClassNameMap;
  handleSortChange: Function;
  handleOrderChange: Function;
  sortBy: string;
  sortingSelected: boolean;
  sortOptionsOpen: boolean;
  orderBy: string;
  setSortOptionsOpen: Dispatch<SetStateAction<boolean>>;
  setSortingSelected: Dispatch<SetStateAction<boolean>>;
  ownershipChange: Function;
}) => {
  const {
    classes,
    sortBy,
    sortingSelected,
    setSortOptionsOpen,
    handleSortChange,
    handleOrderChange,
    setSortingSelected,
    sortOptionsOpen,
    orderBy,
    ownershipChange,
  } = options;

  return [
    {
      name: 'workItemType',
      title: (
        <Box className={classes.firstColTitle}>
          <SortLabelComponent
            title={i18n.t(TEXT.COMMON.FILTERS.INPUT_LABELS.WORK_ITEM_TYPE)}
            name={AssetRecommendationSortingProperties.RecommendationTypeLabel}
            sortBy={sortBy}
            columnIdentifier={AssetRecommendationSortingProperties.RecommendationTypeLabel}
            sortDirection={orderBy}
            sortOptionsOpen={sortOptionsOpen}
            setSortOptionsOpen={setSortOptionsOpen}
            sortingSelected={sortingSelected}
          >
            <ColumnSort
              propertyName={AssetRecommendationSortingProperties.RecommendationTypeLabel}
              options={[
                {
                  key: 'DESC',
                  value: i18n.t(TEXT.COMMON.SORT.A_TO_Z),
                },
                {
                  key: 'ASC',
                  value: i18n.t(TEXT.COMMON.SORT.Z_TO_A),
                },
              ]}
              sortBy={sortBy}
              orderBy={orderBy}
              handleSortChange={handleSortChange}
              handleOrderByChange={handleOrderChange}
              sortPopUpOpen={setSortOptionsOpen}
              setSortingSelected={setSortingSelected}
            />
          </SortLabelComponent>
        </Box>
      ),
      width: '25%',
      getCellValue: (row: any) => (
        <Link onClick={() => ownershipChange(row)}>
          <TextWithToolTip
            fontSize="14px"
            color={CAT_COLOR.STRONG_BLUE}
            underline={true}
            longText={
              row?.recommendationDetails?.recommendationType.label ===
              AssetRecommendationType.CodeEnum.OWNERSHIPCHANGE
                ? i18n.t(TEXT.ASSET_RECOMMENDATION.ASSET_OWNERSHIP_CHANGE.TITLE)
                : row?.recommendationDetails?.recommendationType.label || '--'
            }
            maxWidth="200px"
            marginTop="5px"
            marginLeft="16px"
          />
        </Link>
      ),
    },
    {
      name: 'customer',
      title: (
        <SortLabelComponent
          title={i18n.t(TEXT.COMMON.TITLE.CUSTOMER)}
          name={AssetRecommendationSortingProperties.CustomerOrganizationBusinessName}
          sortBy={sortBy}
          columnIdentifier={AssetRecommendationSortingProperties.CustomerOrganizationBusinessName}
          sortDirection={orderBy}
          sortOptionsOpen={sortOptionsOpen}
          setSortOptionsOpen={setSortOptionsOpen}
          sortingSelected={sortingSelected}
        >
          <ColumnSort
            propertyName={AssetRecommendationSortingProperties.CustomerOrganizationBusinessName}
            options={[
              {
                key: 'DESC',
                value: i18n.t(TEXT.COMMON.SORT.A_TO_Z),
              },
              {
                key: 'ASC',
                value: i18n.t(TEXT.COMMON.SORT.Z_TO_A),
              },
            ]}
            sortBy={sortBy}
            orderBy={orderBy}
            handleSortChange={handleSortChange}
            handleOrderByChange={handleOrderChange}
            sortPopUpOpen={setSortOptionsOpen}
            setSortingSelected={setSortingSelected}
          />
        </SortLabelComponent>
      ),
      getCellValue: (row: any) => (
        <Box display="flex" flexDirection="column">
          <TextWithToolTip
            fontWeight={700}
            fontSize="14px"
            longText={
              row?.recommendedCustomerOrganization?.customerOrganizationBusinessName || '--'
            }
            maxWidth="200px"
            color={CAT_COLOR.BLACK_OLIVE}
          />
          <Box display="flex">
            <Box paddingRight="4px">{i18n.t(TEXT.COMMON.ID)}:</Box>
            <TextWithToolTip
              fontSize="14px"
              longText={
                row?.recommendedCustomerOrganization?.customerOrganizationIdentifier || '--'
              }
              maxWidth="200px"
              color={CAT_COLOR.BLACK_OLIVE}
            />
          </Box>
        </Box>
      ),
      width: '25%',
      align: 'left',
    },
    {
      name: 'workItemGroupID',
      title: (
        <SortLabelComponent
          title={i18n.t(TEXT.ASSET_RECOMMENDATION.COLUMNS.WORK_ITEM_GROUP_ID)}
          name={AssetRecommendationSortingProperties.RecommendationGroupIdentifier}
          sortBy={sortBy}
          columnIdentifier={AssetRecommendationSortingProperties.RecommendationGroupIdentifier}
          sortDirection={orderBy}
          sortOptionsOpen={sortOptionsOpen}
          setSortOptionsOpen={setSortOptionsOpen}
          sortingSelected={sortingSelected}
        >
          <ColumnSort
            propertyName={AssetRecommendationSortingProperties.RecommendationGroupIdentifier}
            options={[
              {
                key: 'DESC',
                value: i18n.t(TEXT.COMMON.SORT.A_TO_Z),
              },
              {
                key: 'ASC',
                value: i18n.t(TEXT.COMMON.SORT.Z_TO_A),
              },
            ]}
            sortBy={sortBy}
            orderBy={orderBy}
            handleSortChange={handleSortChange}
            handleOrderByChange={handleOrderChange}
            sortPopUpOpen={setSortOptionsOpen}
            setSortingSelected={setSortingSelected}
          />
        </SortLabelComponent>
      ),
      width: '25%',
      getCellValue: (row: any) => (
        <TextWithToolTip
          fontSize="14px"
          longText={row?.recommendationDetails?.recommendationGroupIdentifier || '--'}
          maxWidth="200px"
          color={CAT_COLOR.BLACK_OLIVE}
        />
      ),
      align: 'left',
    },
    {
      name: 'initiatedDate',
      title: (
        <SortLabelComponent
          title={i18n.t(TEXT.ASSET_RECOMMENDATION.COLUMNS.INITIATE_DATE)}
          name={AssetRecommendationSortingProperties.InitiatedTime}
          sortBy={sortBy}
          columnIdentifier={AssetRecommendationSortingProperties.InitiatedTime}
          sortDirection={orderBy}
          sortOptionsOpen={sortOptionsOpen}
          setSortOptionsOpen={setSortOptionsOpen}
          sortingSelected={sortingSelected}
        >
          <ColumnSort
            propertyName={AssetRecommendationSortingProperties.InitiatedTime}
            options={[
              {
                key: 'DESC',
                value: i18n.t(TEXT.COMMON.SORT.MOST_RECENT),
              },
              {
                key: 'ASC',
                value: i18n.t(TEXT.COMMON.SORT.LEAST_RECENT),
              },
            ]}
            sortBy={sortBy}
            orderBy={orderBy}
            handleSortChange={handleSortChange}
            handleOrderByChange={handleOrderChange}
            sortPopUpOpen={setSortOptionsOpen}
            setSortingSelected={setSortingSelected}
          />
        </SortLabelComponent>
      ),
      width: '25%',
      getCellValue: (row: any) =>
        `${dayjs(row?.recommendationDetails?.initiatedTime).format('MMM DD, YYYY')}`,

      align: 'left',
    },
  ];
};

export default AssetRecommendationColumns;
