import type { Location as routerLocation } from '@remix-run/router';
import { RelationStatusCode } from '../../store/asset-ownership/types';
import { AssociationForSubscribedAndUnsubscribedAsset } from '../../entities/assets-v2/associationForSubscribedAndUnsubscribedAsset';
import { SubscribedAndUnsubscribedAsset } from '../../entities/assets-v2/subscribedAndUnsubscribedAsset';
import { DcnRelationStatusCode } from '../../entities/assets-v2/dcnRelationStatusCode';
import { UserBasedPermission } from '../../entities/entitlements-v1/userBasedPermission';
import { ServiceBasedPermission } from '../../entities/entitlements-v1/serviceBasedPermission';
import { Condition } from '../../entities/entitlements-v1/condition';
import { findPrimarayPartyNumber } from '../../store/permissions/selectors';
import { PartyType } from '../../entities/entitlements-v1/partyType';
import { PartiesResponse } from '../../entities/entitlements-v1/partiesResponse';
import { isCatAdminUser } from '../../utils/util';

const customerInContext = (location: routerLocation | Location) => {
  return location.pathname.startsWith('/customers/');
};

export const addedBeforeDcn = (selectedAsset: SubscribedAndUnsubscribedAsset | any) => {
  return selectedAsset?.ownership?.associations && selectedAsset?.ownership?.associations.length > 0
    ? selectedAsset?.ownership?.associations
        .filter((ownershipRecord: AssociationForSubscribedAndUnsubscribedAsset) => {
          return ![RelationStatusCode.EXPIRED, DcnRelationStatusCode.EXPIREPENDING].includes(
            ownershipRecord.dcnRelationStatus?.code?.toString() as RelationStatusCode,
          );
        })
        .map((ownershipRecord: AssociationForSubscribedAndUnsubscribedAsset) => {
          return ownershipRecord;
        })
        .map((item: any) => {
          const data = item.dealerCode + '|' + item.dcn;
          return data;
        })
    : [''];
};
export const determineCATSerial = (serialNumber: string | undefined) => {
  if (serialNumber) {
    const catRegex = /^[\dA-Za-z]{3}\d{5}$/;
    if (serialNumber.length === 8) {
      return catRegex.test(serialNumber);
    }
  }
  return false;
};

export const getPartyNumber = (permissionsResponse:  UserBasedPermission | ServiceBasedPermission | undefined,
  partiesResponse: PartiesResponse | undefined, dealerCode?: string) => {

    // if dealercode is passed (in case it is selected by selectign Ownership Type)
    if(dealerCode){
      return (isCatAdminUser(permissionsResponse as UserBasedPermission)) ?
        PartyType.CAT : dealerCode;
    }

  if(!permissionsResponse || !partiesResponse) return;
  const filterConditions: Condition | undefined =
  permissionsResponse?.dataPermissions?.assetDetail?.update?.filterConditions &&
  permissionsResponse?.dataPermissions?.assetDetail?.update?.filterConditions.length > 0
    ? permissionsResponse?.dataPermissions?.assetDetail?.update?.filterConditions[0]
    : undefined;

    if(filterConditions !== undefined && filterConditions?.partyNumbers?.length>1) {
      return (findPrimarayPartyNumber(partiesResponse) as string);
    }
    else if (filterConditions !== undefined && filterConditions?.partyNumbers?.length===1) {
      return (filterConditions.partyNumbers[0]);
    } else {
      return (PartyType.CAT);    }
  
}
export default customerInContext;
