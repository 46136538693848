import { default as Axios, AxiosResponse, CancelToken } from 'axios';
import {
  UsersResponse,
  PatchUserAddUpdateEntitlementCatInput,
  PatchUserAddUpdateEntitlementCatSuperAdminInput,
  PatchUserAddUpdateEntitlementNonCatInput,
  FiltersAndSearchResponseAttributesRequest,
  User,
} from '../entities/user-management-v1/models';
import { AuthInfo } from '../model/types';
import { ErrorResponse } from '../entities/customer-master-v1/models';

class UserManagement {
  private authInfo: AuthInfo | null;

  private baseUrl: string;

  constructor(authInfo: AuthInfo | null, baseUrl: string) {
    this.authInfo = authInfo;
    this.baseUrl = baseUrl;
  }

  searchUsers(
    input: SearchUsersRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<UsersResponse>> {
    const token = this.authInfo ? this.authInfo.token : '';
    const { body } = input;
    return Axios.post(`${this.baseUrl}/users/search`, body, {
      cancelToken,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateCatUser(
    input:
      | PatchUserAddUpdateEntitlementCatInput
      | PatchUserAddUpdateEntitlementNonCatInput
      | PatchUserAddUpdateEntitlementCatSuperAdminInput
      | any,
    catrecid: string,
  ): Promise<AxiosResponse<User | ErrorResponse>> {
    const token = this.authInfo ? this.authInfo.token : '';
    return Axios.patch(`${this.baseUrl}/users/${catrecid}`, input, {
      headers: {
        'Content-type': 'application/vnd.cat.usermanagement-patch+json',
        Authorization: `Bearer ${token}`,
        'x-cat-ent-appsupp-impersonation': true,
      },
    });
  }
}

export type SearchUsersRequest = {
  body?: FiltersAndSearchResponseAttributesRequest;
  xCatUserPreferences?: string;
};

export default UserManagement;
