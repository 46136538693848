import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme) => ({
  iconSection: {
    minWidth: theme.spacing(2.5),
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    boxShadow: theme.shadows[2],
    fontSize: 12,
    minHeight: 34,
    lineHeight: 1.5,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  arrow: {
    color: theme.palette.common.white,
    '&::before': {
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    },
  },
}));
