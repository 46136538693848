import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import Excavator from '../../assets/icons/excavator';

type InitialLoadingPropertyTypes = {
  secondaryMessage?: string;
};
const InitialLoading: FC<InitialLoadingPropertyTypes> = (props: InitialLoadingPropertyTypes) => {
  const { secondaryMessage = '' } = props;
  const { classes } = useStyles();
  return (
    <Box mt="35vh" data-testid="app-initial-loading">
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="row">
        <Excavator />
        <Typography variant="h1" className={classes.title}>
          loading...
        </Typography>
      </Box>
      <Box mt={3} display="flex" alignItems="center" justifyContent="center">
        <Typography>{secondaryMessage}</Typography>
      </Box>
    </Box>
  );
};

export default InitialLoading;
